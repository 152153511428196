import Header from 'shared/components/Header';
import CreateNewOfferWrapper from './CreateNewOfferWrapper';
import s from './styles.module.scss';

const CreateNewOffer = () => (
  <div className={s.createNewOffer}>
    <Header />
    <CreateNewOfferWrapper />
  </div>
);

export default CreateNewOffer;
