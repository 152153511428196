import SelectInput from 'shared/components/SelectInput';
import SelectionCard from 'pages/CreateNewOffer/SelectionCard';
import {useState} from 'react';
import {THEMES} from 'shared/helpers';
import s from './Third.module.scss';

const iconTitles = ['New Development', 'Redevelopment', 'Acquisition', 'Refinance'];

const selectOptionsMock = ['Status 1', 'Status 2', 'Status 3'];

const Third = () => {
  const [selectedCard, setSelectedCard] = useState('');

  return (
    <div className={s.third}>
      <div className={s.third__header}>
        <p className={s.third__title}>What is the purpose of the financing?</p>
      </div>
      <div className={s.third__cards}>
        {iconTitles.map((iconTitle) => (
          <SelectionCard
            key={iconTitle}
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
            title={iconTitle}
            iconName={iconTitle.toLocaleLowerCase()}
          />
        ))}
      </div>
      <div className={s.third__inputs}>
        <SelectInput label="Select Acquisition Status" theme={THEMES.white} options={selectOptionsMock} />
        <SelectInput label="Target Closing Date" theme={THEMES.white} options={selectOptionsMock} />
      </div>
    </div>
  );
};

export {Third};
