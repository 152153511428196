import {FormikElement} from 'core/enums/formik-controller.enum';
import {MaxValidationLimits} from 'core/_consts';
import Controller from 'shared/components/FormikController';
import {useFormikContext} from 'formik';
import {FC} from 'react';

type Props = {
  index: number;
};

const FixedPercent: FC<Props> = ({index}) => {
  const {setFieldValue} = useFormikContext();

  return (
    <Controller
      control={FormikElement.NUMERIC_INPUT}
      width="80px"
      label="Rate"
      preventionLimit={MaxValidationLimits.percent}
      name={`[${index}].fixedPercent`}
      setFieldValue={setFieldValue}
      sign="%"
    />
  );
};

export default FixedPercent;
