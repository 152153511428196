import React from 'react';
import {Provider} from 'react-redux';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import store from 'store/store';
import {ErrorBoundary} from 'react-error-boundary';
import ErrorFallback from 'shared/components/ErrorFallback';
import html2pdf from 'html2pdf.js';
import Toast from './shared/components/Toast';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <ErrorBoundary fallbackRender={ErrorFallback}>
          <App />
          <Toast />
        </ErrorBoundary>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

setTimeout(() => {
  if (window.location.hash === '#pdf') {
    document.body.className += ' print';
    html2pdf()
      .set({
        pagebreak: {mode: 'css', before: '.page-break-before'},
        html2canvas: {useCORS: true},
        jsPDF: {unit: 'in', format: 'a4', orientation: 'p'},
        margin: 0.5,
      })
      .from(document.body)
      .save();
  }
}, 1000);
