import {FC, useState, useEffect, useLayoutEffect} from 'react';
import {useDispatch} from 'react-redux';
import {IconButton} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {storageKey} from 'core/_consts';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import {useHistory, useLocation} from 'react-router-dom';
import cx from 'classnames';
import {vaultService} from 'core/service/storage';
import {quoteTermsStorageType} from 'core/_types/quoteTermsTypes';
import {setInitialValues} from 'store/quote-terms-service/quoteTermsSlice';
import {QuoteTermsInitialValues} from 'core/utils/quoteTermsValues';
import {queryParams} from 'core/utils';
import StepSlider from 'shared/components/StepSlider';
import {THEMES} from 'shared/helpers';
import {NewOfferSliderSteps} from '../helpers';
import {First, Second, Third, Fourth, Fifth, Sixth, Seventh, Eighth} from '../Steps';

import s from './CreateNewOfferWrapper.module.scss';

const useStyles = makeStyles({
  button: {
    position: 'absolute',
    padding: 0,
    color: 'white',
    font: '600 18px Source Sans Pro',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '@media(max-width: 768px)': {
      display: 'none',
    },
  },
  close: {
    right: 20,
    top: 20,
  },
  back: {
    left: 20,
    bottom: 20,
    gap: '5px',
  },
  next: {
    right: 20,
    bottom: 20,
    gap: '5px',
  },
});

const stepsTitles = Array(13)
  .fill('Step')
  .map((item, index) => `${item} ${index + 1}`);

type StepKeysType = keyof typeof NewOfferSliderSteps;

const CreateNewOfferWrapper: FC = () => {
  const [isStartPoint, setIsStartPoint] = useState(false);
  const [step, setStep] = useState<StepKeysType>(NewOfferSliderSteps[NewOfferSliderSteps.first] as StepKeysType);

  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();

  const classes = useStyles();

  const setStoreFromLocalStorage = () => {
    const data = vaultService.getItem<quoteTermsStorageType>(storageKey.quoteTermsData);
    const state = new QuoteTermsInitialValues(data);
    dispatch(setInitialValues(state));
  };

  const renderStep = () => {
    switch (step) {
      case NewOfferSliderSteps[NewOfferSliderSteps.first]:
        return <First />;

      case NewOfferSliderSteps[NewOfferSliderSteps.second]:
        return <Second />;

      case NewOfferSliderSteps[NewOfferSliderSteps.third]:
        return <Third />;

      case NewOfferSliderSteps[NewOfferSliderSteps.fourth]:
        return <Fourth />;
      case NewOfferSliderSteps[NewOfferSliderSteps.fifth]:
        return <Fifth />;

      case NewOfferSliderSteps[NewOfferSliderSteps.eighth]:
        return <Eighth />;

      case NewOfferSliderSteps[NewOfferSliderSteps.sixth]:
        return <Sixth />;

      case NewOfferSliderSteps[NewOfferSliderSteps.seventh]:
        return <Seventh />;

      default:
        return null;
    }
  };

  const nextStep = () => {
    const currentStep = NewOfferSliderSteps[
      NewOfferSliderSteps[step as keyof typeof NewOfferSliderSteps] + 1
    ] as StepKeysType;
    setStep(currentStep);
    history.push(location.pathname + queryParams({step: currentStep}));
  };

  useEffect(() => {
    setStoreFromLocalStorage();
  }, []);

  useLayoutEffect(() => {
    const currentStep =
      (new URLSearchParams(location.search).get('step') as StepKeysType) ||
      NewOfferSliderSteps[NewOfferSliderSteps.first];
    setStep(currentStep);
    setIsStartPoint(
      new URLSearchParams(location.search).get('step') === NewOfferSliderSteps[NewOfferSliderSteps.first]
    );
  }, [location.search]);

  return (
    <div className={s.createNewOfferWrapper}>
      <IconButton
        className={cx(classes.button, classes.close)}
        disableRipple
        onClick={() => history.go(-1)}
        size="small"
      >
        <CloseIcon />
      </IconButton>
      <div className={s.createNewOfferWrapper__content}>{renderStep()}</div>
      <div className={s.createNewOfferWrapper__sliderWrapper}>
        {step && (
          <StepSlider
            titles={stepsTitles}
            theme={THEMES.white}
            steps={Object.values(NewOfferSliderSteps)}
            currentStep={NewOfferSliderSteps[step as keyof typeof NewOfferSliderSteps]}
          />
        )}
      </div>
      {!isStartPoint && (
        <IconButton
          className={cx(classes.button, classes.back)}
          disableRipple
          onClick={() => history.go(-1)}
          size="small"
        >
          <ArrowBackIcon />
          <span>Back</span>
        </IconButton>
      )}
      <IconButton className={cx(classes.button, classes.next)} disableRipple onClick={nextStep} size="small">
        <span>Next</span>
        <ArrowForwardIcon />
      </IconButton>
    </div>
  );
};

export default CreateNewOfferWrapper;
