import {createAsyncThunk} from '@reduxjs/toolkit';
import Ava from 'core/service/index.js';
import {toast} from 'react-toastify';
import {AvaType} from './interfaces';
import 'react-toastify/dist/ReactToastify.css';

export const authenticateAva = createAsyncThunk(
  'ava/authenticateAva',
  (shareToken: string): Promise<AvaType | void> =>
    (shareToken ? Ava.authenticateWithShareToken(shareToken) : Promise.reject())
      .catch(() => Ava.authenticateSession())
      .then((ava) => ava)
      .catch((e) => toast.error(`${e.message}`))
);
