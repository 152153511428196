import {useState} from 'react';
import SelectInput from 'shared/components/SelectInput';
import makeStyles from '@mui/styles/makeStyles';
import BasicTooltip from 'shared/components/BasicTooltip';
import {BORDERS, THEMES} from 'shared/helpers';
import {Form, Formik} from 'formik';
import {MaxValidationLimits} from 'core/_consts';
import NumericInput from 'shared/components/NumericInput';
import {useSelector} from 'react-redux';
import {selectDollarLoanAmount} from 'store/quote-terms-service/quoteTermsSelector';
import {validateNumericInput} from 'core/utils';
import Controller from 'shared/components/FormikController';
import {FormikElement} from 'core/enums/formik-controller.enum';

import s from './Fifth.module.scss';

const selectOptionsMock = ['Value Add', 'Stabilized', 'Vacant'];

const useStyles = makeStyles({
  hint: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '14px',
    height: '14px',
    borderRadius: '50%',
    backgroundColor: 'white',
    color: 'blue',
    cursor: 'default',
    marginLeft: '4px',
    marginTop: '2px',
  },
  label: {
    color: `white`,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '18px',
    fontStyle: 'normal',
  },
});

const Fifth = () => {
  const classes = useStyles();
  const defaultValue = useSelector(selectDollarLoanAmount);
  const [dollarAmountValue, setDollarAmountValue] = useState(Number(defaultValue) || '');

  return (
    <div className={s.fifth}>
      <div className={s.fifth__header}>
        <p className={s.fifth__title}>Property income</p>
      </div>
      <div>
        <Formik
          initialValues={{
            label: '',
          }}
          onSubmit={(values) => {
            // TODO: for debugging in future
            // eslint-disable-next-line no-alert
            alert(JSON.stringify(values));
          }}
        >
          <Form id="user-form" className={s.fifth__form}>
            <div className={s.fifth__formSelect}>
              <label htmlFor="firstName" className={classes.label}>
                Category*
              </label>
              <SelectInput theme={THEMES.white} options={selectOptionsMock} />
            </div>
            <div>
              <label htmlFor="label" className={s.fifth__label}>
                Current Net Operating Income
                <BasicTooltip
                  title="12 months of total income minus operating expenses. Do not include financing costs as per of your calculation."
                  arrow
                  placement="bottom"
                >
                  <div className={classes.hint}>?</div>
                </BasicTooltip>
              </label>
              <NumericInput
                theme={THEMES.white}
                defaultValue={dollarAmountValue}
                onValueChange={(values) => setDollarAmountValue(Number(values.value))}
                thousandSeparator
                isValid={validateNumericInput(Number(dollarAmountValue), MaxValidationLimits.highestLimit)}
                width="300px"
                preventionLimit={MaxValidationLimits.highestLimit}
                placeholder="$"
                textAlign="left"
              />
            </div>
            <div>
              <label htmlFor="label" className={s.fifth__label}>
                Pro Forma Net Operating Income
              </label>
              <NumericInput
                theme={THEMES.white}
                defaultValue={dollarAmountValue}
                onValueChange={(values) => setDollarAmountValue(Number(values.value))}
                thousandSeparator
                isValid={validateNumericInput(Number(dollarAmountValue), MaxValidationLimits.highestLimit)}
                width="300px"
                preventionLimit={MaxValidationLimits.highestLimit}
                placeholder="$"
                textAlign="left"
              />
            </div>
            <div>
              <label htmlFor="label" className={s.fifth__label}>
                Pro Forma Cop Rate
              </label>
              <Controller
                className={s.fifth__label}
                theme={THEMES.white}
                border={BORDERS.SolidWhite1px}
                control={FormikElement.NUMERIC_INPUT}
                width="300px"
                preventionLimit={MaxValidationLimits.percent}
                name="fixedPercent"
                placeholder="%"
                textAlign="left"
              />
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export {Fifth};
