import React from 'react';
import {ReactComponent as CloseIcon} from 'shared/media/Close.svg';
import s from './ErrorFallback.module.sass';

const ErrorFallback = () => (
  <div className={s.fallback}>
    <div className={s.fallback__content}>
      <CloseIcon className={s.fallback__icon} />
      <p className={s.fallback__info}>Ooops.. Something went wrong.. Check console to see error details.</p>
    </div>
  </div>
);

export default ErrorFallback;
