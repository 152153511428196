import {useEffect, useState, useRef} from 'react';
import StepTextInput from 'shared/components/StepTextInput';

import s from './Fourth.module.scss';

const Fourth = () => {
  const [mainAddress, setMainAddress] = useState('');
  const [optionalAddress, setOptionalAddress] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [zip, setZip] = useState('');

  const mainAddressField = useRef<HTMLInputElement>(null);
  const optionalAddressField = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!mainAddressField.current) {
      return;
    }

    const autoComplete = new (window as any).google.maps.places.Autocomplete(mainAddressField.current, {
      componentRestrictions: {country: 'us'},
      fields: ['address_components', 'geometry'],
      types: ['address'],
      strictBounds: false,
    });

    autoComplete.addListener('place_changed', () => {
      const addressObject = autoComplete.getPlace();
      const responseQuery = addressObject.formatted_address;

      if (responseQuery) {
        setMainAddress(responseQuery);
      }

      addressObject?.address_components?.forEach((component: any) => {
        const componentType = component.types[0];

        switch (componentType) {
          case 'postal_code':
            setZip(component.short_name);
            break;
          case 'locality':
            setCity(component.long_name);
            break;
          case 'administrative_area_level_1':
            setState(component.short_name);
            break;
          default:
            break;
        }
      });
    });
  }, [mainAddress]);

  useEffect(() => {
    if (!optionalAddressField.current) {
      return;
    }

    const autoComplete = new (window as any).google.maps.places.Autocomplete(optionalAddressField.current, {
      componentRestrictions: {country: 'us'},
      fields: ['address_components', 'geometry'],
      types: ['address'],
      strictBounds: false,
    });

    autoComplete.addListener('place_changed', () => {
      const addressObject = autoComplete.getPlace();
      const responseQuery = addressObject.formatted_address;

      if (responseQuery) {
        setOptionalAddress(responseQuery);
      }
    });
  }, [optionalAddress]);

  return (
    <div className={s.fourth}>
      <div className={s.fourth__header}>
        <p className={s.fourth__title}>What is the address of your project?</p>
        <p className={s.fourth__subtitle}>
          If you are financing multiple properties together, input the address of one of the properties.
        </p>
      </div>
      <div className={s.fourth__inputsContainer}>
        <div className={s.fourth__inputs}>
          <StepTextInput
            autoCompleteRef={mainAddressField}
            id="address1"
            label="Street Address 1*"
            placeholder="Enter street address"
            width="460px"
            required
          />
          <StepTextInput
            autoCompleteRef={optionalAddressField}
            id="address2"
            label="Street Address 2 (Optional)"
            placeholder="Enter street address"
            width="460px"
          />
          <div className={s.fourth__inputsRow}>
            <StepTextInput
              value={city}
              onValueChange={setCity}
              id="city"
              label="City*"
              placeholder="Enter city"
              width="140px"
              required
            />
            <StepTextInput
              value={state}
              onValueChange={setState}
              id="state"
              label="State*"
              placeholder="Enter state"
              width="140px"
              required
            />
            <StepTextInput
              value={zip}
              onValueChange={setZip}
              id="zip"
              label="Zip Code*"
              placeholder="Enter zip code"
              width="140px"
              required
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export {Fourth};
