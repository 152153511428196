import {RootState} from 'store/store';
import {CapitalAdvisorProfilesType, UserSettingsType} from './interfaces';

export const selectorGetCapitalAdvisorProfiles = (store: RootState): CapitalAdvisorProfilesType =>
  store.userRedux.capitalAdvisorProfiles;

export const selectorGetTenants = (store: RootState): any =>
store.orgRedux.tenants;

export const selectorGetUserSettings = (store: RootState): UserSettingsType => store.userRedux.userSettings;
