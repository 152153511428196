import {useEffect, useState, useMemo} from 'react';
import RadioGroup from 'shared/components/RadioGroup';
import NumericInput from 'shared/components/NumericInput';
import NextButton from 'shared/components/NextButton';
import {MaxValidationLimits, PrepayPenaltySubsteps, QuoteTermsSteps, RecourseSubsteps} from 'core/_consts';
import {validateNumericInput} from 'core/utils';
import TextInput from 'shared/components/TextInput';
import {setBurnOffTrigger} from 'store/quote-terms-service/quoteTermsSlice';
import {selectBurnOffTrigger} from 'store/quote-terms-service/quoteTermsSelector';
import {useSelector} from 'react-redux';
import {nextButtonClickHandler, NextButtonRefType} from 'pages/LenderView/helpers';

enum doesTheRecourseBurnOffOptions {
  constructionCompletion = 'construction completion',
  occupancy = '% occupancy',
  ltv = 'LTV',
  dscr = 'DSCR',
  other = 'other',
}

const BurnOffTrigger = () => {
  const defaultValues = useSelector(selectBurnOffTrigger);
  const [selectedBurn, setSelectedBurn] = useState<Array<string>>(defaultValues.selectedBurn);
  const [numericInputValue, setNumericInputValue] = useState(defaultValues.numericInputValue);
  const [otherValue, setOtherValue] = useState(defaultValues.otherValue);
  const [nextButtonRef, setNextButtonRef] = useState<NextButtonRefType>(null);

  const isConstructionCompletion = selectedBurn.includes(doesTheRecourseBurnOffOptions.constructionCompletion);

  const slideData = useMemo(
    () => ({
      [RecourseSubsteps[RecourseSubsteps.burnOffTrigger]]: {
        selectedBurn,
        numericInputValue: !selectedBurn.includes(doesTheRecourseBurnOffOptions.other) ? numericInputValue : null,
        otherValue: selectedBurn.includes(doesTheRecourseBurnOffOptions.other) ? otherValue : '',
      },
    }),
    [selectedBurn, numericInputValue, otherValue]
  );

  const getInputSign = (item: string) => (item === doesTheRecourseBurnOffOptions.dscr ? 'x' : '%');

  const getSubtitle = (activeSectionTitle: string) => {
    switch (activeSectionTitle) {
      case doesTheRecourseBurnOffOptions.occupancy:
        return 'What percent occupancy is required?';
      case doesTheRecourseBurnOffOptions.ltv:
        return 'Specify LTV';
      case doesTheRecourseBurnOffOptions.dscr:
        return 'Specify DSCR';
      case doesTheRecourseBurnOffOptions.other:
        return 'Specify other trigger';
      default:
        return null;
    }
  };

  const switchValidation = (activeSectionTitle: string) => {
    switch (activeSectionTitle) {
      case doesTheRecourseBurnOffOptions.occupancy:
      case doesTheRecourseBurnOffOptions.ltv:
      case doesTheRecourseBurnOffOptions.dscr:
        return !validateNumericInput(numericInputValue, MaxValidationLimits.percent);
      case doesTheRecourseBurnOffOptions.other:
        return !otherValue.length;
      default:
        return false;
    }
  };

  const switchSection = (activeSectionTitle: string) => {
    switch (activeSectionTitle) {
      case doesTheRecourseBurnOffOptions.occupancy:
      case doesTheRecourseBurnOffOptions.ltv:
      case doesTheRecourseBurnOffOptions.dscr:
        return (
          <div className="FormSlide__form">
            <p className="FormSlide__subTitle mb25 mt25">{getSubtitle(activeSectionTitle)}</p>
            <NumericInput
              onValueChange={(values) => setNumericInputValue(Number(values.value))}
              isValid={validateNumericInput(numericInputValue, MaxValidationLimits.percent)}
              preventionLimit={MaxValidationLimits.percent}
              width="80px"
              defaultValue={numericInputValue || ''}
              sign={getInputSign(activeSectionTitle)}
            />
          </div>
        );
      case doesTheRecourseBurnOffOptions.other:
        return (
          <div className="FormSlide__form">
            <p className="FormSlide__subTitle mb25 mt25">{getSubtitle(activeSectionTitle)}</p>
            <TextInput
              onValueChange={setOtherValue}
              maxLength={20}
              isValid={!!otherValue.length}
              width="200px"
              textAlign="center"
              defaultValue={otherValue}
            />
          </div>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (selectedBurn.length && isConstructionCompletion) nextButtonClickHandler(nextButtonRef);
  }, [selectedBurn, isConstructionCompletion]);

  return (
    <div className="FormSlide">
      <p className="FormSlide__title mb35">What is the burn off trigger?</p>
      <RadioGroup
        maxWidth="840px"
        additionalClassName="burnOffTrigger"
        isAnyValueSelected={selectedBurn}
        setIsAnyValueSelected={setSelectedBurn}
        radioButtonTitles={Object.values(doesTheRecourseBurnOffOptions)}
        nextButtonTrigger={() => nextButtonClickHandler(nextButtonRef)}
      />
      {switchSection(selectedBurn[0])}
      <NextButton
        setNextButtonRef={setNextButtonRef}
        isHidden={isConstructionCompletion}
        isDisabled={!selectedBurn.length || switchValidation(selectedBurn[0])}
        slideData={slideData}
        action={setBurnOffTrigger}
        nextSlide={{
          step: QuoteTermsSteps[QuoteTermsSteps.prepayPenalty],
          substep: PrepayPenaltySubsteps[PrepayPenaltySubsteps.anyPenalty],
        }}
      />
    </div>
  );
};

export default BurnOffTrigger;
