/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {FC} from 'react';
import cx from 'classnames';
import s from './DataCell.module.scss';

export interface DataCellProps {
  title: string;
  data?: string | number;
  dataHtml?: string;
  isTruthy?: boolean;
  bold?: boolean;
}

const DataCell: FC<DataCellProps> = ({data, dataHtml, title, isTruthy = true, bold = true}) => (
  <>
    {(!!data || !!dataHtml) && isTruthy && (
      <div className={s.dataCell}>
        <p className={cx(s.dataCell, s.dataCell_highlighted)}>{title}</p>
        {data ? (
          <p className={cx(s.dataCell, bold && s.dataCell_bolded)}>{data}</p>
        ) : (
          <p className={cx(s.dataCell, bold && s.dataCell_bolded)} dangerouslySetInnerHTML={{__html: dataHtml!}} />
        )}
      </div>
    )}
  </>
);

export default DataCell;
