import {FC, useState, useEffect, useLayoutEffect} from 'react';
import {Accordion, AccordionSummary, AccordionDetails} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import cx from 'classnames';
import {windowSizes} from 'core/_consts';
import s from './AccordionWrapper.module.scss';

const useStyles = makeStyles(() => ({
  root: {
    borderBottom: '1px solid #dde4e4',
    boxShadow: 'unset',
    backgroundColor: 'transparent',
    '&.Mui-expanded': {
      margin: '0',
    },
  },
  summary: {
    height: '90px',
    padding: '0',
    backgroundColor: 'white',
    cursor: 'default',
    '&:hover:not(.Mui-disabled)': {
      cursor: '	auto',
    },
    '@media(max-width: 425px)': {
      height: '60px',
      padding: '0 10px',
    },
  },
  expandIcon: {
    display: 'none',
    '@media(max-width: 425px)': {
      display: 'block',
      width: '30px',
      height: '30px',
      color: '#5999D8',
    },
  },
  details: {
    display: 'block',
    padding: '0',
  },
  mobile: {
    '@media(min-width: 426px)': {
      display: 'none',
    },
  },
  withoutBorder: {
    border: 'unset',
  },
}));

type AccordionWrapperProps = {
  title?: string;
  isAllOpened?: boolean;
  titleForMobile?: boolean;
  withoutBorder?: boolean;
};

const AccordionWrapper: FC<AccordionWrapperProps> = ({children, title, isAllOpened, titleForMobile, withoutBorder}) => {
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);
  const classes = useStyles();

  useLayoutEffect(() => {
    //setIsAccordionExpanded(isAllOpened || false);
  }, [isAllOpened]);

  useLayoutEffect(() => {
    if (window.innerWidth > windowSizes.mobileMiddle) {
      setIsAccordionExpanded(true);
    }
  }, []);

  useEffect(() => {
    const resizeHandler = () => {
      if (window.innerWidth <= windowSizes.mobileMiddle) {
        //setIsAccordionExpanded(false);
      } else {
        setIsAccordionExpanded(true);
      }
    };
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  return (
    <Accordion
      className={cx(classes.root, s.accordion, {[classes.withoutBorder]: withoutBorder})}
      expanded={isAccordionExpanded}
      disableGutters
    >
      <AccordionSummary
        className={cx(classes.summary, {[classes.mobile]: titleForMobile})}
        onClick={() => setIsAccordionExpanded((prev) => !prev)}
        expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
      >
        <p className="LenderView__infoContentTitle">{title}</p>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>{children}</AccordionDetails>
    </Accordion>
  );
};

export default AccordionWrapper;
