import {FC} from 'react';
import {useSelector} from 'react-redux';
import {selectorGetProject} from 'store/project-service/selector';
import s from './LoanRequest.module.scss';

const LoanRequest: FC = () => {
  const project = useSelector(selectorGetProject);

  return (
    <div className={s.loanRequest}>
      <div className={s.loanRequest__text}>
        <p className={s.loanRequest__total}>{project.principalNeeded?.formatSignificant(4).replace(' to ', '-')}</p>
        <p>capital request</p>
      </div>
    </div>
  );
};

export default LoanRequest;
