import {FormikElement} from 'core/enums/formik-controller.enum';
import {MaxValidationLimits, SelectInputIndexOptions} from 'core/_consts';
import Controller from 'shared/components/FormikController';
import {useFormikContext} from 'formik';
import {FC} from 'react';

type Props = {
  index: number;
};

const Floating: FC<Props> = ({index}) => {
  const {setFieldValue} = useFormikContext();

  return (
    <div className="FormSlide__section flexWrap">
      <div className="FormSlide__section gap5">
        <Controller
          control={FormikElement.SELECT}
          options={SelectInputIndexOptions}
          label="Index"
          name={`[${index}].floating.index`}
        />
        <Controller
          control={FormikElement.NUMERIC_INPUT}
          width="80px"
          label="Spread"
          setFieldValue={setFieldValue}
          sign="+"
          reversed
          preventionLimit={MaxValidationLimits.percent}
          name={`[${index}].floating.spread`}
        />
        <p className="FormSlide__sign mb10">%</p>
      </div>
      <div className="FormSlide__section flexWrap">
        <div className="FormSlide__section">
          <Controller
            control={FormikElement.NUMERIC_INPUT}
            width="80px"
            preventionLimit={MaxValidationLimits.percent}
            label="Rate Floor"
            setFieldValue={setFieldValue}
            sign="%"
            name={`[${index}].floating.rateFloor`}
          />
          {/* <Controller
            control={FormikElement.NUMERIC_INPUT}
            width="80px"
            label="Index Floor"
            preventionLimit={MaxValidationLimits.percent}
            setFieldValue={setFieldValue}
            sign="%"
            name={`[${index}].floating.indexFloor`}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default Floating;
