import {useEffect, useState} from 'react';
import RadioGroup from 'shared/components/RadioGroup';
import {AmortizationSubsteps, InterestRateSubsteps, QuoteTermsSteps} from 'core/_consts';
import NextButton from 'shared/components/NextButton';
import {setRateCalculation} from 'store/quote-terms-service/quoteTermsSlice';
import {useSelector} from 'react-redux';
import {selectRateCalculation} from 'store/quote-terms-service/quoteTermsSelector';
import {nextButtonClickHandler, NextButtonRefType} from 'pages/LenderView/helpers';

enum calculateOptions {
  'actual/360' = 'actual/360',
  '30/360' = '30/360',
  // '30/365' = '30/365',
}

const RateCalculation = () => {
  const defaultValue = useSelector(selectRateCalculation);
  const [selectedRateCalculation, setSelectedRateCalculation] = useState<Array<string>>(defaultValue);
  const [nextButtonRef, setNextButtonRef] = useState<NextButtonRefType>(null);

  useEffect(() => {
    nextButtonClickHandler(nextButtonRef);
  }, [selectedRateCalculation]);

  return (
    <div className="FormSlide">
      <p className="FormSlide__title mb35">How should we calculate this interest rate?</p>
      <RadioGroup
        isAnyValueSelected={selectedRateCalculation}
        setIsAnyValueSelected={setSelectedRateCalculation}
        radioButtonTitles={Object.values(calculateOptions)}
        nextButtonTrigger={() => nextButtonClickHandler(nextButtonRef)}
      />
      <NextButton
        isHidden={!defaultValue.length}
        setNextButtonRef={setNextButtonRef}
        isDisabled={!selectedRateCalculation.length}
        slideData={{[InterestRateSubsteps[InterestRateSubsteps.rateCalculation]]: selectedRateCalculation}}
        action={setRateCalculation}
        nextSlide={{
          step: QuoteTermsSteps[QuoteTermsSteps.amortization],
          substep: AmortizationSubsteps[AmortizationSubsteps.amortizationPeriod],
        }}
      />
    </div>
  );
};

export default RateCalculation;
