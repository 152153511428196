import {FC, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import {selectorGetProject} from 'store/project-service/selector';
import {selectorGetAva} from 'store/ava-service/selector';
import {QuoteTermsFormStatus, ROUTERS, windowSizes} from 'core/_consts';
import {useLocation, matchPath} from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import useWindowWidth from 'core/utils/hooks/useWindowWidth';
import Header from 'shared/components/Header';
import ProjectNameHeader from './ProjectNameHeader';
import ProjectInfoCard from './ProjectInfoCard';
import PhotoSlider from './PhotoSlider';
import QuoteTermsButtons from './QuoteTermsButtons';
import Summary from './Summary';
import PhotoGallery from './PhotoGallery';
import SourcesAndUses from './SourcesAndUses';
import DesignAndConstruction from './DesignAndConstruction';
import Guarantor from './Guarantor';
import Sponsor from './Sponsor';
import Attachments from './Attachments';
import UsersPanel from './UsersPanel';
import LoanRequest from './LoanRequest';
import ProFormaSummary from './ProFormaSummary';
import KeyStats from './KeyStats';
import MultiTenant from './MultiTenant';
import SingleTenant from './SingleTenant';
import QuoteTermsWrapper from './QuoteTermsWrapper';
import s from './LenderView.module.scss';
import NonBindingOffer from './QuoteTermsWrapper/Steps/NonBindingOffer';
import SimpleNonBindingOffer from './QuoteTermsWrapper/Steps/NonBindingOffer/simple';
import OfferAccordion from './QuoteTermsWrapper/OfferAccordion';

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10px',
  },
});

const LenderView: FC = () => {
  // TODO: get valid data from server (at least consist of current offer (submited on the form)) (Array<DataObjects>)
  const [userOffers, setUserOffers] = useState(['1']);
  const [images, setImages] = useState<Array<string>>([]);
  const [galleryItemNumber, setGalleryItemNumber] = useState(-1);
  const [isAllOpened, setisAllOpened] = useState<boolean>(false);

  const ava = useSelector(selectorGetAva);
  (window as any).debug_ava = ava;
  const project = useSelector(selectorGetProject);
  (window as any).debug_project = project;

  const location = useLocation();
  const formPosition = new URLSearchParams(location.search).get('formPosition') || QuoteTermsFormStatus.closed;

  const windowWidth = useWindowWidth();

  const isMobileView = windowWidth <= windowSizes.mobileMiddle;

  const usingShare = !!ava.sharedUser.token;

  const hasOffer = project.offerList?.size > 0 || formPosition === QuoteTermsFormStatus.submitted;

  const nonBindingOfferStatus =
      hasOffer /* ||
      matchPath(location.pathname, {
        path: ROUTERS.NON_BINDING_OFFER,
      }) */;

  const isQuoteTermsFormOpened =
      (formPosition === QuoteTermsFormStatus.topOpened || formPosition === QuoteTermsFormStatus.bottomOpened)
      && (
          !matchPath(location.pathname, {
            path: ROUTERS.NON_BINDING_OFFER,
          })
          || nonBindingOfferStatus);

  const classes = useStyles();

  useEffect(() => {
    if (Object.getOwnPropertyNames(project).length) {
      setImages(Array.from(project.imageList).map((image: any) => image.url));
    }
  }, [project]);

  const needProFormaSummary =
    !!Object.getOwnPropertyNames(project).length &&
    (!project.ltc.isZero() || !project.ltv.isZero()) &&
    !project.stabilizedLtv.isZero() &&
    project.capRateValuation !== project.stabilizedCapRateValuation &&
    project.ltv.upperBound.formatMaxDecimalPlaces(1) !== project.stabilizedLtv.upperBound.formatMaxDecimalPlaces(1);

  const designAndConstructionTests = [
    project.architect !== '',
    project.structuralEngineer !== '',
    project.completedYear > 0,
    project.constructionManager !== '',
    project.generalContractor !== '',
    project.renovatedYear > 0,
    project.leasingRepresentative !== '',
    project.propertyManager !== '',
    project.floorsAboveGround > 0,
    project.floorsBelowGround > 0,
    project.totalSize ? !project.totalSize.isEmpty() : false,
    project.footprintSize ? !project.footprintSize.isEmpty() : false,
    project.lotSize ? !project.lotSize.isEmpty() : false,
  ];
  const needDesignAndConstruction = designAndConstructionTests.some((u) => u);

  const needSponsor =
    !!project.sponsorProfile &&
    (project.sponsorProfile.name !== '' ||
      project.sponsorProfile.yearFounded > 0 ||
      project.sponsorProfile.yearsOfExperience > 0 ||
      project.sponsorProfile.address.line1 !== '' ||
      !project.sponsorProfile.assetsUnderOwnership.isZero());

  const address = encodeURIComponent(project.address?.formatOneLine() || '');

  return (
    <div className={s.app}>
      <div className={s.app__content}>
        <Header />
        <div className={s.lenderView}>
          <Modal
            open={galleryItemNumber !== -1}
            className={classes.modal}
            onClose={() => setGalleryItemNumber(-1)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={galleryItemNumber !== -1}>
              <div className={s.lenderView__sliderFade}>
                <PhotoSlider initialActiveItem={galleryItemNumber} images={images} />
              </div>
            </Fade>
          </Modal>
          <div className={s.lenderView__wrapper}>
            <ProjectNameHeader />
            {
              project.principalNeeded.lowerBound.isZero()
              && project.principalNeeded.upperBound.isZero()
                ? null
                : <LoanRequest />
            }
            <div className={s.lenderView__preview}>
              <ProjectInfoCard />
              <div className="no-print">
                <PhotoSlider images={images.length > 0 ? images : [`https://maps.googleapis.com/maps/api/staticmap?center=${address}&zoom=13&size=490x300&maptype=roadmap&markers=${address}&key=AIzaSyCRKEp1iM16gumouiXM2vSs6D7eYPcQN_s`]} />
              </div>
              <div className="only-print">
                {renderPrintFirstPageImages(
                  images.length > 0
                    ? images
                    : [
                        `https://maps.googleapis.com/maps/api/staticmap?center=${address}&zoom=13&size=490x300&maptype=roadmap&markers=${address}&key=AIzaSyCRKEp1iM16gumouiXM2vSs6D7eYPcQN_s`,
                      ]
                )}
              </div>
            </div>
            {usingShare ? (<>
              {!isQuoteTermsFormOpened && !nonBindingOfferStatus ? (
                  <QuoteTermsButtons quoteTermsFormStatus={formPosition} formPosition={QuoteTermsFormStatus.topOpened} />
                )
                : isQuoteTermsFormOpened && formPosition === QuoteTermsFormStatus.topOpened && !nonBindingOfferStatus ? <QuoteTermsWrapper />
                : (
                  <div className={s.test}>
                    {userOffers.map((data, index, array) => (
                      <OfferAccordion
                        disabled={array.length <= 1}
                        key={data}
                        isExpanded={array.length > 1 ? array.length - 1 === index : true}
                        title="Non-binding Offer"
                      >
                        <SimpleNonBindingOffer />
                      </OfferAccordion>
                    ))}
                  </div>
                )
              }
            </>) : null}
            <div className={s.lenderView__content}>
              <Summary isMobileView={isMobileView} />
              <KeyStats isMobileView={isMobileView} />
              <SourcesAndUses isMobileView={isMobileView} />
              <ProFormaSummary isMobileView={isMobileView} />
              <DesignAndConstruction isMobileView={isMobileView} />
              {
                project.tenancy.type === 0 ? null
                : project.tenancy.tenantList.size === 1
                  ? <SingleTenant isMobileView={isMobileView} ava={ava} />
                : <MultiTenant isMobileView={isMobileView} ava={ava} />
              }
              <Sponsor isMobileView={isMobileView} />
              <Guarantor isMobileView={isMobileView} />
              <PhotoGallery images={images} setGalleryItemNumber={setGalleryItemNumber} />
              <Attachments isMobileView={isMobileView} />
            </div>
            {usingShare ? (<>
              {!isQuoteTermsFormOpened && !nonBindingOfferStatus ? (
                  <QuoteTermsButtons quoteTermsFormStatus={formPosition} formPosition={QuoteTermsFormStatus.bottomOpened} />
                )
                : isQuoteTermsFormOpened && formPosition === QuoteTermsFormStatus.bottomOpened && !nonBindingOfferStatus ? <QuoteTermsWrapper />
                : (
                  <div className={s.test}>
                    {userOffers.map((data, index, array) => (
                      <OfferAccordion
                        disabled={array.length <= 1}
                        key={data}
                        isExpanded={array.length > 1 ? array.length - 1 === index : true}
                        title="Non-binding Offer"
                      >
                        <SimpleNonBindingOffer />
                      </OfferAccordion>
                    ))}
                  </div>
                )
              }
            </>) : null}
            <UsersPanel isMobileView={isMobileView} />
            <div className={s.lenderView__controller}>
              {/* <div className="link">
                {isAllOpened && <span onClick={() => setisAllOpened(false)}>Close all</span>}
                {!isAllOpened && <span onClick={() => setisAllOpened(true)}>Open all</span>}
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const renderPrintFirstPageImages = (images: string[]) =>
  images
    .slice(0, 1)
    .map((imageUrl) => (
      <img
        style={{margin: '8px 0', maxWidth: '100%'}}
        key={imageUrl}
        className={s.lenderView__image}
        src={imageUrl}
        alt=""
      />
    ));

export default LenderView;
