import {FC, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {selectorGetProject} from 'store/project-service/selector';
import DataCell, {DataCellProps} from 'shared/components/DataCell/DataCell';
import AccordionWrapper from 'shared/components/Accordion';
import {IsMobileViewProps} from './helpers';

const filterValuesByBoolean = (values: Array<DataCellProps>) => {
  if (!values) return false;
  return values.map((item) => item.isTruthy ?? item.data).some(Boolean);
};

const DesignAndConstruction: FC<IsMobileViewProps> = ({isMobileView}) => {
  const project = useSelector(selectorGetProject);

  const tableData = useMemo(
    () => [
      {title: 'Architect', data: project.architect},
      {title: 'Structural Engineer', data: project.structuralEngineer},
      {title: 'Year Built', data: project.completedYear},
      {title: 'Construction Manager', data: project.constructionManager},
      {title: 'General Contractor', data: project.generalContractor},
      {title: 'Year Renovated', data: project.renovatedYear},
      {title: 'Leasing Representative', data: project.leasingRepresentative},
      {title: 'Property Manager', data: project.propertyManager},
      {title: 'Floors Above Ground', data: project.floorsAboveGround},
      {title: 'Floors Below Ground', data: project.floorsBelowGround},
      {title: 'Total Floors', data: project.floorsAboveGround + project.floorsBelowGround},
      {title: 'Asset Size', data: project.totalSize?.format(), isTruthy: !project.totalSize?.isEmpty()},
      {title: 'Footprint Size', data: project.footprintSize?.format(), isTruthy: !project.footprintSize?.isEmpty()},
      {title: 'Lot Size', data: project.lotSize?.format(), isTruthy: !project.lotSize?.isEmpty()},
    ],
    [project]
  );

  const content = (
    <div className="LenderView__section">
      {!isMobileView && <p className="LenderView__infoContentTitle">Design & Construction</p>}
      {!project.designInfo?.isEmpty() ? (
        <p dangerouslySetInnerHTML={{__html: project.designInfo?.formatAsHtml()}} />
      ) : null}
      <div className="LenderView__flexTable">
        {tableData.map((item) => (
          <DataCell data={item.data} title={item.title} isTruthy={item.isTruthy} key={item.title} />
        ))}
      </div>
      {!project.assetNotes?.isEmpty() ? (
        <DataCell title="Asset Notes" bold={false} dataHtml={project.assetNotes?.formatAsHtml()} />
      ) : null}
    </div>
  );

  if (!filterValuesByBoolean(tableData)) return null;
  if (isMobileView) return <AccordionWrapper title="Design & Construction">{content}</AccordionWrapper>;

  return content;
};

export default DesignAndConstruction;
