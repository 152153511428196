import {FC} from 'react';
import {useSelector} from 'react-redux';
import cx from 'classnames';
import BasicButton from 'shared/components/BasicButton';
import {LoanAmountSubsteps, QuoteTermsFormStatus, QuoteTermsSteps, ROUTERS} from 'core/_consts';
import {Link, useHistory, useLocation, useParams} from 'react-router-dom';
import {queryParams} from 'core/utils';
import {URLParams} from 'core/_types/base';
import {selectorGetAva} from 'store/ava-service/selector';
import styles from './QuoteTermsButtons.module.scss';

type QuoteTermsButtonsProps = {
  formPosition: string;
  quoteTermsFormStatus: string;
};

const QuoteTermsButtons: FC<QuoteTermsButtonsProps> = ({formPosition, quoteTermsFormStatus}) => {
  const history = useHistory();
  const location = useLocation();
  const params = useParams<URLParams>();

  const ava = useSelector(selectorGetAva);

  const openQuoteTerms = () =>
  history.push(
    `${ROUTERS.PROJECT}:${params.projectId}/view${queryParams({
      step: QuoteTermsSteps[QuoteTermsSteps.loanAmount],
      substep: LoanAmountSubsteps[LoanAmountSubsteps.loanSizingConstraints],
      formPosition,
    })}${location.hash}`
    );

    const declineQuoteTerms = async () => {
      history.push(
        `${ROUTERS.PROJECT}:${params.projectId}/view${queryParams({
          formPosition: QuoteTermsFormStatus.declined,
        })}${location.hash}`
      );
      await ava.declineProjectShare();
      window.location.href = `https://platform.stacksource.com/project:${params.projectId}/declined${location.hash}`;
    };

    function undeclineQuoteTerms() {
      ava.undeclineProjectShare();
      history.push(
        `${ROUTERS.PROJECT}:${params.projectId}/view${queryParams({
          formPosition: QuoteTermsFormStatus.closed,
        })}${location.hash}`
      );
    }

  return (
    <div
      className={cx(styles.Buttons, {
        [styles.Buttons_declined]: quoteTermsFormStatus === QuoteTermsFormStatus.declined,
        [styles.Buttons_hidden]: formPosition === QuoteTermsFormStatus.bottomOpened,
      })}
    >
      {quoteTermsFormStatus !== QuoteTermsFormStatus.declined ? (
        <>
          <BasicButton onClick={openQuoteTerms} className="blue" variant="contained">
            Quote Terms
          </BasicButton>
          <BasicButton className="white" variant="contained" onClick={declineQuoteTerms}>
            Decline
          </BasicButton>
        </>
      ) : (
        <p>
          You declined to make a loan offer on this project.&nbsp;
          <span className="link" onClick={undeclineQuoteTerms}>
            Undo
          </span>
        </p>
      )}
    </div>
  );
};

export default QuoteTermsButtons;
