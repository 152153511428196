import {ProjectType} from 'store/project-service/interfaces';
import {
  ILoanAmount,
  ILoanTerm,
  IInerestRate,
  ILoanFees,
  IAmortization,
  IRecourse,
  ICashManagement,
  IPrepayPenalty,
  IRequiredReserves,
} from 'store/quote-terms-service/interfaces';
import Ava from 'core/service/index.js';
import {
  IRateDetailsSlideData,
  FixedSpreadType,
  FloatingType,
  BorrowerStipulationsValue,
  LengthAndFeeValues,
} from 'core/_types/quoteTermsTypes';
import {QuoteTermsInitialValues} from 'core/utils/quoteTermsValues';

export default function convertOfferForAva(project: ProjectType, offer: QuoteTermsInitialValues) {
  console.log('offer:', offer); // DEBUG
  // return (Ava as any).Offer.from({
  //   project: project.sym,
  //   principal: `USD ${getLoanAmount(project, offer.loanAmount)}`,
  //   term: getLoanTerms(offer.loanTerm),
  //   interest: getInterest(offer.interestRate),
  //   closing_cost: getClosingCosts(offer.loanFees),
  //   collateral: '',
  //   repayment_schedule:
  // });
  return {
    offer: (Ava as any).DEPRECATED_OfferInfo.from({
      summary: {
        principal: {
          total: `USD ${getLoanAmount(project, offer.loanAmount)}`,
          guarantee: `USD ${getLoanAmount(project, offer.loanAmount)}`,
        },
        loan_term: getLoanTerms(offer.loanTerm),
        closing_cost: getClosingCosts(offer.loanFees, offer.loanTerm.lengthAndFee),
        property_value: project.estimatedValuation.value,
        repayment_schedule: getRepaymentSchedule(offer.amortization, sumLoanTerms(offer.loanTerm)),
        stipulation: getStipulations(offer.loanAmount, offer.borrowerStipulations),
      },
      details: {
        collateral: '',
        insurance_requirement: [],
        recourse: getRecourse(offer.recourse),
        cash_management: getCashManagement(offer.cashManagement),
        prepayment_penalty: getPrepaymentPenalty(offer.prepayPenalty),
        reporting_frequency_requirement: 'NONE',
        other_requirements: {
          type: 'PLAIN',
          content: offer.otherRequirements,
        },
        legal_term_sheet: {},
        interest_rate_calculation_method: getCalculationMethod(offer.interestRate.rateCalculation[0]),
        reserves: getReserves(offer.requiredReserves),
      },
    }),
    interest: getInterest(offer.interestRate),
  };
}

function getLoanAmount(project: ProjectType, loanAmount: ILoanAmount): number {
  if (loanAmount.dollarLoanAmount) {
    return loanAmount.dollarLoanAmount;
  }
  const constraints = loanAmount.loanSizingConstraints;
  const loanSize = (Ava as any).calculateLoanSize(
    project,
    (Ava as any).LoanSizeConstraints.from({
      ltv: (constraints.LTV || 0) / 100,
      ltc: (constraints.LTC || 0) / 100,
      dscr: constraints.DSCR || 0,
      debt_yield: (constraints.DebtYield || 0) / 100,
    }),
    // interest rate
    (Ava as any).Percent.from(0),
    // amortizaiton
    (Ava as any).Term.ofMonths(0)
  );
  if (loanSize.value.isZero()) {
    throw new Error('Could not determine loan size.');
  }
  return loanSize.value.toNumber();
}

function sumLoanTerms(loanTerm: ILoanTerm): string {
  let sum = 0;
  const terms = getLoanTerms(loanTerm);
  for (let i = 0; i < terms.length; i += 1) {
    const term = terms[i];
    const [length, unit] = term.split(' ');
    if (unit === 'month') {
      sum += Number(length);
    } else {
      sum += Number(length) * 12;
    }
  }
  return `${sum} months`;
}

function getLoanTerms(loanTerm: ILoanTerm): string[] {
  const values = loanTerm.lengthAndFee;
  return [
    `${values.initialTerm} ${values.initialTermDateFormat}`,
    ...values.extensions.map(
      ({extensionDateValue, extensionDateFormat}) => `${extensionDateValue} ${extensionDateFormat}`
    ),
  ];
}

function getInterest(interestRate: IInerestRate) {
  let lastYear = 1;
  const r: any[] = [];
  // Iterating over resetYear + 1 here instead of rateDetails because rateDetails
  // doesn't currently get cleared of extra values when resetYear is modified smaller.
  for (let i = 0; i < interestRate.resetYear.length + 1; i += 1) {
    const item = interestRate.rateDetails[i];
    if (item) {
      const itemYearString = interestRate.resetYear[i] || '';
      const m = /^year (\d+)$/.exec(itemYearString);
      const itemYear = m ? +m[1] : 0;
      r.push(getInterestItem(itemYear === 0 ? 0 : itemYear - lastYear, item));
      lastYear = itemYear;
    }
  }
  return r;
}

function getInterestItem(years: number, item: IRateDetailsSlideData) {
  const out: any = {
    value: getInterestValue(item),
  };
  if (years > 0) {
    out.length = `${years} year`;
  }
  return out;
}

function getInterestValue(item: IRateDetailsSlideData) {
  if (item.fixedPercent) {
    return getFixedInterestValue(item.fixedPercent);
  }
  if (item.fixedSpread) {
    return getFixedSpreadInterestValue(item.fixedSpread);
  }
  if (item.floating) {
    return getFloatingInterestValue(item.floating);
  }
  throw new Error('Could not parse interest value');
}

function getFixedInterestValue(value: number) {
  return {
    index: 'fixed',
    spread: {value: value / 100},
  };
}

function getFixedSpreadInterestValue(item: FixedSpreadType, lockEvent = '') {
  const {index, tenor} = getIndexAndTenor(item.index);
  const spread = getSpread(item.spread, item.rateFloor);
  const lockEventInfo = lockEvent ? {lock_event: lockEvent} : getLockEvent(item.fixedAt);
  switch (index) {
    case 'LIBOR':
    case 'LIBOR_SWAP':
      return {
        index,
        tenor: liborTenor(tenor),
        lock_event: lockEventInfo.lock_event,
        spread,
      };
    case 'PRIME':
    case 'SOFR':
      return {
        index,
        lock_event: lockEventInfo.lock_event,
        spread,
      };
    case 'US_TREASURY':
    case 'US_TREASURY_SWAP':
      return {
        index,
        tenor: usTreasuryTenor(tenor),
        lock_event: lockEventInfo.lock_event,
        spread,
      };
    case 'CME_TERM_SOFR':
      return {
        index,
        tenor: cmeTermSofrTenor(tenor),
        lock_event: lockEventInfo.lock_event,
        spread,
      };
    case 'SOFR_SWAP':
      return {
        index,
        tenor: sofrSwapTenor(tenor),
        lock_event: lockEventInfo.lock_event,
        spread,
      };
    default:
      throw new Error(`Unknown rate index ${JSON.stringify(index)}`);
  }
}

function liborTenor(tenor: string) {
  switch (tenor) {
    case '1 months':
      return 'ONE_MONTH';
    case '3 months':
      return 'THREE_MONTHS';
    case '6 months':
      return 'SIX_MONTHS';
    case '12 months':
      return 'TWELVE_MONTHS';
    default:
      throw new Error(`Unknown LIBOR tenor ${JSON.stringify(tenor)}`);
  }
}

function usTreasuryTenor(tenor: string) {
  switch (tenor) {
    case '1 years':
      return 'ONE_YEAR';
    case '2 years':
      return 'TWO_YEARS';
    case '3 years':
      return 'THREE_YEARS';
    case '5 years':
      return 'FIVE_YEARS';
    case '7 years':
      return 'SEVEN_YEARS';
    case '10 years':
      return 'TEN_YEARS';
    default:
      throw new Error(`Unknown US Treasury tenor ${JSON.stringify(tenor)}`);
  }
}

function cmeTermSofrTenor(tenor: string) {
  switch (tenor) {
    case '1 months':
      return 'ONE_MONTH';
    default:
      throw new Error(`Unknown CME Term SOFR tenor ${JSON.stringify(tenor)}`);
  }
}

function sofrSwapTenor(tenor: string) {
  switch (tenor) {
    case '5 years':
      return 'FIVE_YEARS';
    case '10 years':
      return 'TEN_YEARS';
    default:
      throw new Error(`Unknown US Treasury tenor ${JSON.stringify(tenor)}`);
  }
}

function getFloatingInterestValue(item: FloatingType) {
  return getFixedSpreadInterestValue(<FixedSpreadType> item, 'NO_LOCK');
}

function getIndexAndTenor(index: string) {
  const [indexName, tenor] = parseIndex(index);
  const out: any = {
    index: getIndex(indexName),
  };
  if (tenor) {
    out.tenor = tenor;
  }
  return out;
}

function getIndex(indexName: string) {
  switch (indexName) {
    case 'Libor':
      return 'LIBOR';
    case 'Libor Swap':
      return 'LIBOR_SWAP';
    case 'Prime':
      return 'PRIME';
    case 'US Treasury':
      return 'US_TREASURY';
    case 'US Treasury Swap':
      return 'US_TREASURY_SWAP';
    case 'SOFR':
      return 'SOFR';
    case 'SOFR Swap':
      return 'SOFR_SWAP';
    case 'CME Term SOFR':
      return 'CME_TERM_SOFR';
    default:
      throw new Error(`Could not get index for ${JSON.stringify(indexName)}`);
  }
}

function parseIndex(index = 'US Treasury (1Y)'): [string, string] {
  const m = /^([\w\s]+)(?:\((\d+)(Y|M)\))?$/.exec(index);
  if (!m) {
    throw new Error(`Could not parse index ${JSON.stringify(index)}`);
  }
  const [, name, tenor, tenorUnit] = m;
  return [name.trim(), tenor ? `${tenor} ${convertTenorUnit(tenorUnit)}` : ''];
}

function convertTenorUnit(unit: string): string {
  switch (unit) {
    case 'Y':
      return 'years';
    case 'M':
      return 'months';
    default:
      throw new Error(`Could not convert tenor unit ${JSON.stringify(unit)}`);
  }
}

function getSpread(spread: number, floor: number) {
  return {
    value: (spread || 0) / 100,
    floor: (floor || 0) / 100,
  };
}

function getLockEvent(fixedAt: string) {
  if (!fixedAt) {
    return {lock_event: 'NO_LOCK'};
  }
  return {
    lock_event: lockEventToAvaEnum(fixedAt),
  };
}

function lockEventToAvaEnum(fixedAt: string): string {
  switch (fixedAt) {
    case '':
      return '';
    case 'Signed Term Sheet':
      return 'SIGNED_TERM_SHEET';
    case 'Commitment Letter':
      return 'COMMITMENT_LETTER';
    case 'Day of Close':
      return 'DAY_OF_CLOSE';
    default:
      throw new Error(`Unknown lock event ${JSON.stringify(fixedAt)}`);
  }
}

function getClosingCosts(fees: ILoanFees, lengthAndFee: LengthAndFeeValues) {
  // eslint-disable-next-line no-underscore-dangle
  let _fees = fees;
  if (!_fees) {
    _fees = {
      selectLoanFees: [],
      feesDetails: [],
    };
  }
  const r = _fees.feesDetails.map((fee) => ({
    type: getClosingCostType(fee.name),
    value: getClosingCostValue(fee.amount, fee.amountFormat),
    timing: getClosingCostTiming(fee.dueAt),
  }));
  if (lengthAndFee.originationFee) {
    r.push({
      type: 'ORIGINATION_FEE',
      value: getClosingCostValue(lengthAndFee.originationFee, lengthAndFee.originationFeeValueFormat),
      timing: 'CLOSING',
    });
  }
  return r;
}

function getClosingCostType(name: string): string {
  switch (name) {
    case 'application fee':
      return 'APPLICATION_FEE';
    case 'exit fee':
      // TODO(nathan): Add support for exit fee
      return 'OTHER';
    case 'estimated closing cost':
      // TODO(nathan): Add support for estimated closing cost
      return 'OTHER';
    case 'mortgage insurance premium':
      return 'MORTGAGE_INSURANCE_PREMIUM';
    default:
      // TODO(nathan): Add support for custom types
      return 'OTHER';
  }
}

function getClosingCostValue(amount: number, amountFormat: string): string {
  switch (amountFormat) {
    case '$':
      return `USD ${amount}`;
    case '%':
      return `${amount}%`;
    default:
      throw new Error(`Unknown fee amount format ${JSON.stringify(amountFormat)}`);
  }
}

function getClosingCostTiming(dueAt: string): string {
  switch (dueAt) {
    case 'Closing':
      return 'CLOSING';
    case 'Application':
      return 'APPLICATION';
    case 'Loan Maturity':
      return 'LOAN_MATURITY';
    default:
      throw new Error(`Unknown fee deadline ${JSON.stringify(dueAt)}`);
  }
}

function getRepaymentSchedule(amort: IAmortization, fullIoTerm: string) {
  const amortPeriod = amort.amortizationPeriod[0];
  switch (amortPeriod) {
    case 'interest only':
      return {
        interest_only_period: fullIoTerm,
        amortization_period: '0 years',
      };
    case 'other':
      return {
        interest_only_period: getInterestOnlyPeriod(amort),
        amortization_period: `${amort.amount.amortizationAmount} ${amort.amount.dateFormat}`,
      };
    default:
      return {
        interest_only_period: getInterestOnlyPeriod(amort),
        amortization_period: amortPeriod,
      };
  }
}

function getInterestOnlyPeriod(amort: IAmortization): string {
  return amort.interestOnlyPeriod[0] === 'yes'
    ? `${amort.interestOnlyPeriodDuration.dateValue} ${amort.interestOnlyPeriodDuration.dateFormat}`
    : '0 years';
}

function getStipulations(
  loanAmount: ILoanAmount,
  stipulations: BorrowerStipulationsValue = {creditScore: null, netWorth: null, liquidityAfterClosing: null}
) {
  const constraints = loanAmount.loanSizingConstraints;
  const out: any[] = [];
  if (constraints.LTC) {
    out.push({
      type: 'LTC',
      percent: constraints.LTC / 100,
    });
  }
  if (constraints.LTV) {
    out.push({
      type: 'LTV',
      percent: constraints.LTV / 100,
    });
  }
  if (constraints.DSCR) {
    out.push({
      type: 'DSCR',
      percent: constraints.DSCR,
    });
  }
  if (constraints.DebtYield) {
    out.push({
      type: 'Debt Yield',
      percent: constraints.DebtYield / 100,
    });
  }
  if (stipulations.creditScore) {
    out.push({
      type: 'Guarantor Credit Score',
      number: stipulations.creditScore,
    });
  }
  if (stipulations.netWorth) {
    out.push({
      type: 'Guarantor Net Worth',
      percent: stipulations.netWorth / 100,
    });
  }
  if (stipulations.liquidityAfterClosing) {
    out.push({
      type: 'Guarantor Liquidity After Closing',
      term: `${stipulations.liquidityAfterClosing} months`,
    });
  }
  return out;
}

function getRecourse(recourse: IRecourse) {
  return {
    type: getRecourseType(recourse.availableRecourse.selectedRecourse[0]),
    recourse_limit: recourse.availableRecourse.partialRecoursePercent
      ? recourse.availableRecourse.partialRecoursePercent / 100
      : 0,
  };
}

function getRecourseType(type: string): string {
  switch (type) {
    case 'non-recourse':
      return 'NO_RECOURSE';
    case 'full recourse':
      return 'FULL';
    case 'partial recourse':
      return 'LIMITED';
    default:
      throw new Error(`Unknown recourse type ${JSON.stringify(type)}`);
  }
}

// TODO(nathan): We need to update the backend to support new dropdox types and
// allow selecting more than 1.
function getCashManagement(cashManagement: ICashManagement|undefined) {
  if (!cashManagement || cashManagement.isLockboxNeeded[0] === 'no') {
    return 'NONE';
  }
  const types = cashManagement.selectLockboxType;
  const hard = types.includes('hard lockbox');
  const soft = types.includes('soft lockbox');
  const immediate = types.includes('immediate lockbox');
  const springing = types.includes('springing lockbox');
  switch (true) {
    case hard && springing:
      return 'SPRINGING_HARD_LOCKBOX';
    case soft && springing:
      return 'SPRINGING_SOFT_LOCKBOX';
    case hard:
      return 'IMMEDIATE_HARD_LOCKBOX';
    case soft:
      return 'IMMEDIATE_SOFT_LOCKBOX';
    case immediate:
      return 'IMMEDIATE_HARD_LOCKBOX';
    case springing:
      return 'SPRINGING_HARD_LOCKBOX';
    default:
      return 'NONE';
  }
}

function getPrepaymentPenalty(penalty: IPrepayPenalty|undefined) {
  if (!penalty || penalty.anyPenalty[0] === 'no') {
    return {type: 'NONE'};
  }
  switch (penalty.penaltyType[0]) {
    case 'defeasance':
      return {
        type: 'DEFEASANCE',
      };
    case 'yield maintenance':
      return {
        type: 'YIELD_MAINTENANCE',
      };
    case 'stepdown':
      return {
        type: 'DECLINING',
        declining_details: {
          period_rate: penalty.prepaymentPenalty.years.map((info, i) => ({
            period: {
              initial: {
                unit: 'MONTH',
                length: i * 12,
              },
              final: {
                unit: 'MONTH',
                length: (i + 1) * 12,
              },
            },
            rate: Number(info.value) / 100,
          })),
        },
      };
    // TODO(nathan): There are more cases that need to be implemented.
    default:
      throw new Error('Unimplemented');
  }
}

function getCalculationMethod(method: string) {
  if (!method) {
    return 'UNSET';
  }
  switch (method) {
    case 'actual/360':
      return 'ACTUAL_PER_THREE_SIXTY';
    case '30/360':
      return 'THIRTY_PER_THREE_SIXTY';
    // TODO(nathan): There are more cases that need to be implemented.
    default:
      throw new Error('Unimplemented');
  }
}

function getReserves(reserves: IRequiredReserves | undefined) {
  if (!reserves) {
    return [];
  }
  return reserves.reservesDetails.map(({name, upfront, monthly, cap}) => ({
    type: getReservesType(name),
    upfront: `USD ${upfront || 0}`,
    monthly: `USD ${monthly || 0}`,
    cap: `USD ${cap || 0}`,
  }));
}

function getReservesType(name: string) {
  switch (name) {
    case 'taxes':
      return 'TAXES_AND_INSURANCE';
    case 'insurance':
      return 'TAXES_AND_INSURANCE';
    case 'interest/operating':
      return 'INTEREST_AND_OPERATING';
    case 'CAPEX':
      return 'CAPEX';
    case 'TI/LC':
      return 'TENANT_IMPROVEMENTS_AND_LEASING_COMISSION';
    case 'other':
      return 'OTHER';
    default:
      throw new Error(`Unknown reserves type ${JSON.stringify(name)}`);
  }
}
