import {FC, useEffect, useState} from 'react';
import {ROUTERS} from 'core/_consts';
import {Switch, Route, useLocation, matchPath, match, useHistory} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import LenderView from 'pages/LenderView';
import {useSelector, useDispatch} from 'react-redux';
import {getUserSettings} from 'store/user-service/asyncActions';
import {parseShareToken, queryParams} from 'core/utils';
import {authenticateAva} from 'store/ava-service/asyncActions';
import {selectorGetAva} from 'store/ava-service/selector';
import {getProject} from 'store/project-service/asyncActions';
import {selectorGetProject} from 'store/project-service/selector';
import {StyledCircularProgress} from 'shared/components/CircularLoader';
import CreateNewOffer from 'pages/CreateNewOffer';
import {NewOfferSliderSteps} from 'pages/CreateNewOffer/helpers';

const App: FC = () => {
  const [projectId, setProjectId] = useState<string>();

  const location = useLocation();
  const history = useHistory();

  const dispatch = useDispatch();
  const ava = useSelector(selectorGetAva);
  const project = useSelector(selectorGetProject);
  const [loaderState, setLoaderState] = useState(true);

  if (!project) {
    (window as any).location = 'https://platform.stacksource.com/user/login';
    return (<div>Loading...</div>);
  }

  useEffect(() => {
    const setProjectSym = () => {
      const matchedUrl: match<{projectId: string}> | null = matchPath(location.pathname, {
        path: ROUTERS.PROJECT_VIEW,
      });
      if (matchedUrl) setProjectId(matchedUrl.params.projectId);
      else {
        setLoaderState(false);
        window.location.href = 'https://www.stacksource.com';
      }
    };
    const authenticateAvaBySharedToken = () => {
      dispatch(authenticateAva(parseShareToken(location.hash)));
    };
    setProjectSym();
    authenticateAvaBySharedToken();
  }, []);

  useEffect(() => {
    if (ava && projectId) {
      dispatch(getUserSettings(ava));
      dispatch(getProject({projectSym: projectId, ava}));
    }
  }, [ava, projectId]);

  useEffect(() => {
    if (Object.getOwnPropertyNames(project).length) setLoaderState(false);
  }, [project]);

  if (loaderState) return <StyledCircularProgress />;

  return (
    <>
      <Switch>
        <Route path={ROUTERS.PROJECT_VIEW} component={LenderView} />
        <Route path={ROUTERS.CREATE_NEW_OFFER} component={CreateNewOffer} />
      </Switch>
    </>
  );
};

export default App;
