import {FC} from 'react';
import cx from 'classnames';
import {FieldInputProps, FieldMetaProps} from 'formik';
import NumberFormat, {NumberFormatValues} from 'react-number-format';
import {preventExceedOfLimit} from 'core/utils';
import {THEMES} from 'shared/helpers';

import s from './NumericInput.module.scss';

type NumericInputProps = {
  theme?: string;
  border?: string;
  name?: string;
  className?: string;
  width?: string;
  label?: string;
  sign?: string;
  placeholder?: string;
  isValid?: boolean;
  color?: string;
  textAlign?: 'left' | 'center' | 'right';
  background?: 'none' | 'inherit' | 'transparent';
  reversed?: boolean;
  maxLength?: number;
  field?: FieldInputProps<any>;
  meta?: FieldMetaProps<any>;
  withIcon?: boolean;
  thousandSeparator?: boolean;
  preventionLimit?: number;
  decimalScale?: number;
  defaultValue?: string | number;
  allowNegative?: boolean;
  onValueChange?: (values: NumberFormatValues) => void;
  setFieldValue?: (name: string, value: string | number | null, isValidated: boolean) => void;
};

const NumericInput: FC<NumericInputProps> = ({
  label,
  width,
  theme,
  border,
  color,
  sign,
  placeholder,
  preventionLimit,
  maxLength,
  isValid,
  setFieldValue,
  defaultValue,
  field,
  meta,
  reversed,
  allowNegative,
  onValueChange,
  thousandSeparator,
  textAlign = 'center',
  decimalScale = 2,
}) => (
  <div
    className={cx(s.numericInput, {
      [s.numericInput_reversed]: reversed,
      [s.numericInput_white]: theme === THEMES.white,
    })}
  >
    {label && <p className="FormSlide__inputLabel">{label}</p>}
    <NumberFormat
      {...field}
      onChange={(e: any) => {
        field?.onChange(e.target.value);
      }}
      decimalScale={decimalScale}
      onValueChange={(values) =>
        onValueChange ? onValueChange?.(values) : setFieldValue?.(field?.name || '', values.floatValue || null, true)
      }
      defaultValue={defaultValue}
      thousandSeparator={thousandSeparator}
      isAllowed={(data: NumberFormatValues) => (preventionLimit ? preventExceedOfLimit(data, preventionLimit) : true)}
      isNumericString
      allowNegative={allowNegative}
      maxLength={maxLength}
      style={{textAlign, width, background: theme === THEMES.white ? 'none' : '', border, color}}
      placeholder={placeholder}
      className={cx(s.numericInput__item, {inputValid: isValid ?? (!meta?.error && (meta?.touched || field?.value))})}
    />
    {sign && <p className={cx(s.numericInput__sign, {[s.numericInput__sign_reversed]: reversed})}>{sign}</p>}
  </div>
);
export default NumericInput;
