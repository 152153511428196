import {useEffect, useRef, useState} from 'react';
import {queryParams} from 'core/utils';
import {useHistory, useLocation} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import cx from 'classnames';
import {SlideIdentifier} from 'core/_types/quoteTermsTypes';
import {Dictionary} from 'core/_models/base';
import {vaultService} from 'core/service/storage';
import {storageKey} from 'core/_consts';
import makeStyles from '@mui/styles/makeStyles';
import useIsStartPointSetter from 'core/utils/hooks/useIsStartPoint';
import BasicButton from './BasicButton';

const useStyles = makeStyles({
  button: {
    display: 'none',
    padding: 0,
    color: '#848484',
    font: '600 18px Source Sans Pro',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '@media(max-width: 768px)': {
      display: 'flex',
    },
  },
});

type NextButtonProps = {
  nextSlide: SlideIdentifier;
  slideData: Array<Dictionary<any>> | Dictionary<any>;
  action?: any;
  isHidden?: boolean;
  isDisabled?: boolean;
  submitForm?: () => void;
  sideEffect?: () => void;
  setNextButtonRef?: (ref: any) => void;
};

type LocationStateType = {
  isEditingMode: boolean;
};

const NextButton = ({
  nextSlide,
  isDisabled,
  slideData,
  isHidden,
  action,
  submitForm,
  sideEffect,
  setNextButtonRef,
}: NextButtonProps) => {
  const [isStartPoint, setIsStartPoint] = useState(false);

  const nextButton = useRef<HTMLButtonElement>(null);

  const history = useHistory();
  const location = useLocation<LocationStateType>();
  const dispatch = useDispatch();

  const classes = useStyles();

  const nextButtonTitle = location.state?.isEditingMode ? 'Submit' : 'Next';

  const onClickHandler = () => {
    sideEffect?.();
    vaultService.updateItem(storageKey.quoteTermsData, slideData);
    if (action)
      (Array.isArray(action) ? action : [action]).forEach((item: any, index: number) =>
        dispatch(item?.(Object.values(slideData)[index]))
      );
    submitForm?.();
    const formPosition = new URLSearchParams(location.search).get('formPosition') || '';
    if (location.state?.isEditingMode) history.go(-1);
    else history.push(`${location.pathname}${queryParams(Object.assign(nextSlide, {formPosition}))}${location.hash}`);
  };

  useIsStartPointSetter(setIsStartPoint);

  useEffect(() => {
    setNextButtonRef?.(nextButton);
  }, []);

  return (
    <div className="FormSlide__navigation">
      {!isStartPoint && (
        <BasicButton className={classes.button} disableRipple onClick={() => history.go(-1)}>
          Back
        </BasicButton>
      )}
      <BasicButton
        ref={nextButton}
        disabled={isDisabled}
        onClick={onClickHandler}
        className={cx('nextButton', {blue: !isDisabled, grey: isDisabled, 'd-none': isHidden ?? setNextButtonRef})}
        variant="contained"
      >
        {nextButtonTitle}
      </BasicButton>
    </div>
  );
};

export default NextButton;
