import {useCallback, useMemo} from 'react';
import {queryParams} from 'core/utils';
import {
  LoanAmountSubsteps,
  QuoteTermsSteps,
  LoanTermSubsteps,
  MaxValidationLimits,
  SizingConstrainsLabels,
  storageKey,
} from 'core/_consts';
import Ava from 'core/service/index.js';
import {Link, useLocation} from 'react-router-dom';
import NextButton from 'shared/components/NextButton';
import {ProjectType} from 'store/project-service/interfaces';
import {setLoanSizingConstraints} from 'store/quote-terms-service/quoteTermsSlice';
import {Formik, Form} from 'formik';
import {selectLoanSizingConstraints} from 'store/quote-terms-service/quoteTermsSelector';
import {useSelector} from 'react-redux';
import {selectorGetProject} from 'store/project-service/selector';
import Controller from 'shared/components/FormikController';
import {FormikElement} from 'core/enums/formik-controller.enum';
import {sizingConstraintsSchema} from 'core/helpers/yupSchemas';
import {vaultService} from 'core/service/storage';
import {SizingConstrainsValues} from 'core/_types/quoteTermsTypes';
import s from './SizingConstraints.module.scss';

const getInputSign = (item: string) => (item === SizingConstrainsLabels.DSCR ? 'x' : '%');

const SizingConstraints = () => {
  const project = useSelector(selectorGetProject);
  const defaultValues = useSelector(selectLoanSizingConstraints);

  const location = useLocation();
  const formPosition = new URLSearchParams(location.search).get('formPosition') || '';

  const dollarLoanAmountPath = useMemo(
    () => ({
      pathname: `${location.pathname}`,
      search: `${queryParams({
        step: QuoteTermsSteps[QuoteTermsSteps.loanAmount],
        substep: LoanAmountSubsteps[LoanAmountSubsteps.dollarLoanAmount],
        formPosition,
      })}`,
      hash: `${location.hash}`,
    }),
    [location, formPosition]
  );

  const updateLoanSizeData = (values: SizingConstrainsValues) => {
    const loanSize = (Ava as any).calculateLoanSize(
      project,
      (Ava as any).LoanSizeConstraints.from({
        ltv: (values.LTV || 0) / 100,
        ltc: (values.LTC || 0) / 100,
        dscr: (values.DSCR || 0) / 100,
        debt_yield: (values.DebtYield || 0) / 100,
      }),
      (Ava as any).Percent.from(0),
      (Ava as any).Term.ofMonths(0)
    );

    if (!loanSize.constraint || loanSize.constraint === (Ava as any).LoanSizeConstraint.UNKNOWN) {
      return 'We will handle the loan amount calculations for you.';
    }

    return (
      <>
        <span>Loan amount calculated to: </span>
        <span className={s.highlightedData}>{loanSize.value.formatNoDecimal()}</span>
        <span> constrained by: </span>
        <span className={s.highlightedData}>{loanSize.formatConstraint()}</span>
      </>
    );
  };

  const nextButtonSideEffect = useCallback(
    () => vaultService.removeItem(storageKey.quoteTermsData, LoanAmountSubsteps[LoanAmountSubsteps.dollarLoanAmount]),
    []
  );

  return (
    <div className="FormSlide">
      <Formik
        initialValues={defaultValues}
        validationSchema={sizingConstraintsSchema}
        validateOnMount
        // TODO: replace onSubmit
        // eslint-disable-next-line no-console
        onSubmit={(values) => console.log(values)}
      >
        {({values, setFieldValue}) => (
          <Form className="FormSlide__form gap25">
            <div className="FormSlide__heading mb35">
              <p className="FormSlide__title">Tell us one (or more) of your loan sizing constraints.</p>
              <p>{updateLoanSizeData(values)}</p>
            </div>
            <div className={s.inputs}>
              {Object.keys(SizingConstrainsLabels).map((item) => (
                <Controller
                  key={item}
                  name={item}
                  control={FormikElement.NUMERIC_INPUT}
                  setFieldValue={setFieldValue}
                  width="80px"
                  label={SizingConstrainsLabels[item as keyof typeof SizingConstrainsLabels]}
                  sign={getInputSign(item)}
                  preventionLimit={MaxValidationLimits.percent}
                />
              ))}
            </div>
            <p className="FormSlide__redirect">
              I’d rather enter a &nbsp;
              <Link className="link" to={dollarLoanAmountPath}>
                dollar loan amount
              </Link>
              .
            </p>
            <NextButton
              sideEffect={nextButtonSideEffect}
              slideData={{[LoanAmountSubsteps[LoanAmountSubsteps.loanSizingConstraints]]: values}}
              action={setLoanSizingConstraints}
              isDisabled={!Object.values(values).some(Boolean)}
              nextSlide={{
                step: QuoteTermsSteps[QuoteTermsSteps.loanTerm],
                substep: LoanTermSubsteps[LoanTermSubsteps.loanTermDuration],
              }}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SizingConstraints;
