import {FC} from 'react';
import AccordionWrapper from 'shared/components/Accordion';
import {useSelector} from 'react-redux';
import {selectorGetProject} from 'store/project-service/selector';
import {selectorGetTenants} from 'store/user-service/selector';
import {AvaType} from 'store/ava-service/interfaces';
import styles from './MultiTenant.module.scss';

const SingleTenant: FC<{
  isMobileView: boolean;
  ava: AvaType;
}> = ({isMobileView, ava}) => {
  const project = useSelector(selectorGetProject);

  const tenants = useSelector(selectorGetTenants);
  if (tenants.length === 0) {
    return <div />;
  }

  const content = (
    <div className="LenderView__section">
      {!isMobileView && <p className="LenderView__infoContentTitle">Tenants</p>}
      <div className={styles.TenantContainer}>
        {tenants.map((tenant: any) => (
          <div className={styles.Tenant}>
            <div className={styles.Tenant__profile}>
              {console.log('z:', tenant)}
              {tenant.logo.thumbnailUrl && (
                <img
                  className={styles.Tenant__photo}
                  src={tenant.logo.thumbnailUrl}
                />
              )}
              <div className={styles.Tenant__info}>
                <div className="fw600">{tenant.name}</div>
                <div>{tenant.briefDescription}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  // TODO: add conditional section rendering when data will be available
  if (isMobileView) return <AccordionWrapper title="Tenants">{content}</AccordionWrapper>;

  return content;
};

export default SingleTenant;
