import {FC, useMemo} from 'react';
import {feesDetailsDueAtOptions, MaxValidationLimits, signOptions, windowSizes} from 'core/_consts';
import {FeesDetailsValue} from 'core/_types/quoteTermsTypes';
import Controller from 'shared/components/FormikController';
import {FormikElement} from 'core/enums/formik-controller.enum';
import useWindowWidth from 'core/utils/hooks/useWindowWidth';
import s from './FeesDetails.module.scss';

interface IFeesDetailsRowProps {
  children: React.ReactNode;
  fieldName: string;
  rowIndex: number;
  formikValues: Array<FeesDetailsValue>;
  setFieldValue: (name: string, value: number, isValidated: boolean) => void;
}

const setInputLabel = (windowWidth: number, fieldName: string) => {
  if (windowWidth > windowSizes.mobileSmall) return fieldName === 'mortgage insurance premium' ? 'Estimate' : 'Amount';
  return fieldName;
};

const FeesDetailsRow: FC<IFeesDetailsRowProps> = ({children, fieldName, rowIndex, formikValues, setFieldValue}) => {
  const windowWidth = useWindowWidth();

  const responsiveInputWidth = useMemo(() => (windowWidth > windowSizes.tablet ? '100px' : '180px'), [windowWidth]);

  return (
    <div className={s.inputs__section}>
      {children}
      <Controller
        name={`structuredFeesDetails[${rowIndex}].amount`}
        control={FormikElement.NUMERIC_INPUT}
        preventionLimit={
          formikValues[rowIndex].amountFormat === signOptions.dollar
            ? MaxValidationLimits.dollars
            : MaxValidationLimits.percent
        }
        width={responsiveInputWidth}
        thousandSeparator
        setFieldValue={setFieldValue}
        label={setInputLabel(windowWidth, fieldName)}
        textAlign="right"
      />
      <Controller
        name={`structuredFeesDetails[${rowIndex}].amountFormat`}
        control={FormikElement.RADIO_INPUT}
        controlledInputValue={formikValues[rowIndex].amount}
        setFieldValue={setFieldValue}
        values={Object.values(signOptions)}
      />
      <Controller
        name={`structuredFeesDetails[${rowIndex}].dueAt`}
        control={FormikElement.SELECT}
        label="Due at"
        options={Object.values(feesDetailsDueAtOptions)}
        setFieldValue={setFieldValue}
      />
    </div>
  );
};

export default FeesDetailsRow;
