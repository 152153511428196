import {useState} from 'react';
import {InterestRateSubsteps, QuoteTermsSteps} from 'core/_consts';
import RadioGroup from 'shared/components/RadioGroup';
import NextButton from 'shared/components/NextButton';
import {setResetYear} from 'store/quote-terms-service/quoteTermsSlice';
import {useSelector} from 'react-redux';
import {selectResetYear, selectLengthAndFee} from 'store/quote-terms-service/quoteTermsSelector';

enum resetYearOptions {
  oneYear = 'year 2',
  twoYears = 'year 3',
  threeYears = 'year 4',
  fourYears = 'year 5',
  fiveYears = 'year 6',
  sixYears = 'year 7',
  sevenYears = 'year 8',
  eightYears = 'year 9',
  nineYears = 'year 10',
  elevenYears = 'year 12',
  fourteenYears = 'year 15',
  nineteenYears = 'year 20',
  twentyFourYears = 'year 25',
  twentyNineYears = 'year 30',
}

const ResetYear = () => {
  const defaultValue = useSelector(selectResetYear);
  const [isAnyResetYear, setisAnyResetYear] = useState<Array<string>>(defaultValue);

  const lengthAndFee = useSelector(selectLengthAndFee);
  const totalMonths = [
    {
      value: lengthAndFee.initialTerm,
      unit: lengthAndFee.initialTermDateFormat,
    },
    ...lengthAndFee.extensions.map(({extensionDateValue, extensionDateFormat}) => ({
      value: extensionDateValue,
      unit: extensionDateFormat,
    })),
  ]
    .map(({value, unit}: any) => unit === 'years' ? value * 12 : value)
    .reduce((acc, u) => acc + u);
  const totalYears = totalMonths / 12;

  return (
    <div className="FormSlide">
      <div className="FormSlide__heading mb35">
        <p className="FormSlide__title">In what year does the reset occur?</p>
        <p className="FormSlide__subTitle">If more than one, select all that apply.</p>
      </div>
      <RadioGroup
        maxWidth="680px"
        isAnyValueSelected={isAnyResetYear}
        setIsAnyValueSelected={setisAnyResetYear}
        radioButtonTitles={Object.values(resetYearOptions).filter(u => +(/^year (\d+)$/.exec(u) || ['', '1'])[1] <= totalYears)}
        multiSelect
      />
      <NextButton
        isDisabled={!isAnyResetYear.length}
        slideData={{[InterestRateSubsteps[InterestRateSubsteps.resetYear]]: isAnyResetYear}}
        action={setResetYear}
        nextSlide={{
          step: QuoteTermsSteps[QuoteTermsSteps.interestRate],
          substep: InterestRateSubsteps[InterestRateSubsteps.rateDetails],
        }}
      />
    </div>
  );
};

export default ResetYear;
