import {useRef, useState} from 'react';
import TextArea from 'shared/components/TextArea';
import {useDispatch, useSelector} from 'react-redux';
import {ProjectType} from 'store/project-service/interfaces';
import {selectorGetProject} from 'store/project-service/selector';
import {RootState} from 'store/store';
import {ReactComponent as AttachmentIcon} from 'shared/media/Attachment.svg';
import {vaultService} from 'core/service/storage';
import {QuoteTermsFormStatus, QuoteTermsSteps, storageKey} from 'core/_consts';
import {QuoteTermsInitialValues} from 'core/utils/quoteTermsValues';
import BasicButton from 'shared/components/BasicButton';
import {makeStyles} from '@mui/styles';
import {useHistory, useLocation} from 'react-router-dom';
import {selectOtherRequirements} from 'store/quote-terms-service/quoteTermsSelector';
import {queryParams} from 'core/utils';
import {selectorGetAva} from 'store/ava-service/selector';
import convertOfferForAva from './convertOfferForAva';

const useStyles = makeStyles({
  button: {
    display: 'none',
    padding: 0,
    color: '#848484',
    font: '600 18px Source Sans Pro',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '@media(max-width: 768px)': {
      display: 'flex',
    },
  },
});

const OtherRequirements = () => {
  const ava = useSelector(selectorGetAva);
  const defaultValue = useSelector(selectOtherRequirements);
  const dispatch = useDispatch();

  const [fileUpload, setFileUpload] = useState(null);
  const [textAreaValue, setTextAreaValue] = useState(defaultValue);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const history = useHistory();
  const location = useLocation();
  const formPosition = new URLSearchParams(location.search).get('formPosition') || '';

  const classes = useStyles();

  const project = useSelector(selectorGetProject);
  const offer = useSelector((store: RootState) => store.quoteTermsRedux.data);

  const handleSubmit = async () => {
    vaultService.updateItem(storageKey.quoteTermsData, {
      [QuoteTermsSteps[QuoteTermsSteps.otherRequirements]]: textAreaValue,
    });
    // dispatch(setOtherRequirements(textAreaValue));
    try {
      await sendOffer(ava, project, offer, textAreaValue, fileUpload);
    } catch (e) {
      setError(true);
      throw e;
    }
    history.push(`${location.pathname}/non-binding-offer${queryParams({formPosition: QuoteTermsFormStatus.submitted})}${location.hash}`);
  };

  const removeFile = () => {
    setFileUpload(null);
    if (inputRef.current) inputRef.current.value = '';
  };

  return (
    <div className="FormSlide">
      <div className="FormSlide__heading">
        <p className="FormSlide__title">Are there any other requirements?</p>
        <p className="FormSlide__subTitle mb35">
          You can enter any additional details we didn&apos;t cover, or skip it and submit.
        </p>
      </div>
      <TextArea
        defaultValue={defaultValue}
        isValid={!!textAreaValue.length}
        onValueChange={setTextAreaValue}
        width="460px"
        height="85px"
      />
      <input className="d-none" ref={inputRef} id="inputFile" type="file" onChange={e => setFileUpload((e as any).target.files[0] || null)} />
      <div className="FormSlide__navigation">
        {error ? (
          <p style={{color: '#f00', fontWeight: 'bold'}}>
            There was an error submitting your offer. Please contact a StackSource capital advisor, team member, or
            email us at <a href="mailto:hello@stacksource.com">hello@stacksource.com</a>
          </p>
        ) : (
          <>
            <BasicButton className={classes.button} disableRipple onClick={() => history.go(-1)}>
              Back
            </BasicButton>
            {submitting ? (
              'Submitting...'
            ) : (
              <BasicButton onClick={handleSubmit} className="nextButton blue" variant="contained">
                Submit
              </BasicButton>
            )}
          </>
        )}
      </div>
    </div>
  );
};
export default OtherRequirements;

async function sendOffer(ava: any, project: ProjectType, offer: QuoteTermsInitialValues, otherRequirements: string, fileUpload: any) {
  let {offer: avaOffer, interest} = convertOfferForAva(project, offer);
  avaOffer = avaOffer.setDetails(avaOffer.details.setOtherRequirements(
    avaOffer.details.otherRequirements.setContent(otherRequirements)
  ));
  console.log('avaOffer:', avaOffer.toJson()); // DEBUG
  await ava.DEPRECATED_createOffer(project.sym, avaOffer, fileUpload, interest);
}

// DEBUG
(window as any).debug_sendOffer = sendOffer;
