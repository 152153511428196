import {Dictionary} from 'core/_models/base';
import {dateOptions, MaxValidationLimits, signOptions} from 'core/_consts';
import {NumberFormatValues} from 'react-number-format';

export const queryParams = (params?: Dictionary): string =>
  params ? `?${new URLSearchParams(Object.entries(params)).toString()}` : '';

export const preventExceedOfLimit = (values: NumberFormatValues, maxLimit: number): boolean => {
  if (!values.floatValue) return true;
  return values.floatValue >= 0 && values.floatValue <= maxLimit;
};

export const validateNumericInput = (value: number, maxLimit: number): boolean => value > 0 && value <= maxLimit;

export const getMaxLimit = (value: string): number => {
  switch (value) {
    case signOptions.dollar:
      return MaxValidationLimits.dollars;
    case signOptions.percent:
      return MaxValidationLimits.percent;
    case dateOptions.years:
      return MaxValidationLimits.years;
    case dateOptions.months:
      return MaxValidationLimits.months;
    default:
      return -1;
  }
};

export const createGoogleMapsLink = (address: {formatLines: () => Array<string>}): string => {
  const formatedAdress = address.formatLines().map((item) => item.split(' '));
  const adressDetails = formatedAdress.map((item) => item.join('+'));
  const formatedLink = `${adressDetails.join(',')}`;
  return formatedLink;
};

export const getSuffixOf = (value: number) => {
  const remainderOfDivisionByTen = value % 10;
  const percentFraction = value % 100;
  if (remainderOfDivisionByTen === 1 && percentFraction !== 11) {
    return `${value}st`;
  }
  if (remainderOfDivisionByTen === 2 && percentFraction !== 12) {
    return `${value}nd`;
  }
  if (remainderOfDivisionByTen === 3 && percentFraction !== 13) {
    return `${value}rd`;
  }
  return `${value}th`;
};

export const getYearsFromMonths = (value: number, convertationFormat: string) => {
  const monthsInYear = 12;
  return Math.ceil(convertationFormat === dateOptions.months ? value / monthsInYear : value);
};

export const parseShareToken = (hash: string): string => {
  const h = hash.replace(/^#/, '');
  const parts = h.split('&');
  for (let i = 0; i < parts.length; i += 1) {
    const part = parts[i];
    const m = /^s=(\w+)$/.exec(part);
    if (m) {
      return m[1];
    }
  }
  return '';
};

export const filterObjectByTruthyValue = (object: Dictionary) => {
  const entries = Object.entries(object);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const filtered = entries.filter(([_, value]) => !!value);
  return Object.fromEntries(filtered);
};
