export const ROUTERS = {
  PROJECT: '/project',
  PROJECT_VIEW: '/project::projectId/view',
  NON_BINDING_OFFER: '/project::projectId/view/non-binding-offer',

  CREATE_NEW_OFFER: '/create-new-offer',

  // TODO: delete old code
  OVERVIEW: '/project/:projectId/overview',
  ASSET_INFORMATION: '/project/:projectId/asset-information',
  FINANCIAL_INFORMATION: '/project/:projectId/financial-information',
  TENANT_INFORMATION: '/project/:projectId/tenancy',
  SOURCES_AND_USES: '/project/:projectId/sources-and-uses',
  PICTURES: '/project/:projectId/pictures',
  ATTACHMENTS: '/project/:projectId/attachments',
  GUARANTORS: '/project/:projectId/guarantors',
  SPONSOR_PROFILE: '/project/:projectId/sponsor-profile',
  DASHBOARD: '/projects',
  PROFILE: '/profile',
};
