import {FC, useEffect, useState} from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {Accordion, AccordionSummary, AccordionDetails} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DataCell from 'pages/LenderView/QuoteTermsWrapper/Steps/NonBindingOffer/DataCell';
import cx from 'classnames';
import s from './OfferAccordion.module.scss';

const useStyles = makeStyles({
  root: {
    border: '1px solid #DDE4E4',
    boxShadow: 'none',
    '&::before': {
      height: 0,
    },
    '&:last-of-type': {
      borderRadius: '10px',
    },
    '&:first-of-type': {
      borderRadius: '10px',
    },
    '@media(max-width: 425px)': {
      borderWidth: '1px 0',
      '&:last-of-type': {
        borderRadius: 0,
      },
      '&:first-of-type': {
        borderRadius: 0,
      },
    },
  },
  summary: {
    padding: '45px 20px 35px',
    '@media(max-width: 425px)': {
      padding: '25px 20px',
    },
    '&.Mui-disabled': {
      opacity: 1,
    },
    '& .MuiAccordionSummary-content': {
      gap: '105px',
      margin: 0,
    },
    '& .MuiAccordionSummary-content.Mui-expanded div': {
      display: 'none',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(270deg)',
    },
  },
  arrow: {
    color: '#5999d8',
    '&.disabled': {
      display: 'none',
    },
  },
  details: {
    padding: 0,
  },
});

type OfferAccordionProps = {
  title: string;
  isExpanded: boolean;
  disabled: boolean;
};

const OfferAccordion: FC<OfferAccordionProps> = ({children, title, isExpanded, disabled}) => {
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(true);

  const classes = useStyles();

  useEffect(() => {
    setIsAccordionExpanded(isExpanded);
  }, [isExpanded]);

  return (
    <Accordion expanded={isAccordionExpanded} disableGutters className={classes.root}>
      {/*<AccordionSummary
        onClick={() => setIsAccordionExpanded((prev) => !prev)}
        disabled={disabled}
        className={classes.summary}
        expandIcon={<ArrowForwardIosIcon className={cx(classes.arrow, {disabled})} />}
      >
        <p className="LenderView__infoContentTitle">{title}</p>
        {/* TODO: replace mock data * /}
        <div className={s.previewData}>
          <DataCell title="Loan Term" data="5 years" />
          <DataCell title="Loan Term" data="5 years" />
        </div>
      </AccordionSummary> */}
      <AccordionDetails className={classes.details}>
        <div style={{marginTop: '30px'}}>
          {children}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default OfferAccordion;
