import SelectionCard from 'pages/CreateNewOffer/SelectionCard';
import {useState} from 'react';
import s from './First.module.scss';

const iconTitles = [
  'Multi-family',
  'Office',
  'Industrial',
  'Retail',
  'Hospitality',
  'Land',
  'Self Storage',
  'Religious',
  'Hospital',
  'Other',
];

const First = () => {
  const [selectedCard, setSelectedCard] = useState('');

  return (
    <div className={s.first}>
      <div className={s.first__header}>
        <p className={s.first__title}>What is the primary asset type of your project?</p>
        <p className={s.first__subtitle}>
          If your project’s is mixed use, choose the asset type which represents the greatest portion.
        </p>
      </div>
      {iconTitles.map((iconTitle) => (
        <SelectionCard
          key={iconTitle}
          selectedCard={selectedCard}
          setSelectedCard={setSelectedCard}
          title={iconTitle}
          iconName={iconTitle.toLocaleLowerCase()}
        />
      ))}
    </div>
  );
};

export {First};
