export enum dateOptions {
  years = 'years',
  months = 'months',
}

export enum yesNoOptions {
  yes = 'yes',
  no = 'no',
}

export enum signOptions {
  dollar = '$',
  percent = '%',
}

export enum loanFeesOptions {
  applicationFee = 'Application Fee',
  exitFee = 'Exit Fee',
  otherFee = 'Other Fee',
  estimatedClosingCost = 'Estimated Closing Cost',
  mortgageInsurancePremium = 'Mortgage Insurance Premium',
}

export enum feesDetailsDueAtOptions {
  unset = '',
  closing = 'Closing',
  application = 'Application',
  loanMaturity = 'Loan Maturity',
}

export enum requiredReservesOptions {
  taxes = 'taxes',
  insurance = 'insurance',
  interestOrOperating = 'interest/operating',
  CAPEX = 'CAPEX',
  TIorLC = 'TI/LC',
  other = 'other',
}

export enum BorrowerStipulationsLabels {
  creditScore = 'Credit Score',
  netWorth = 'Net Worth',
  liquidityAfterClosing = 'Liquidity After Closing',
}

export enum requiredReservesLabels {
  Upfront = 'upfront',
  Monthly = 'monthly',
  Cap = 'cap',
}

export enum lockboxTypeOptions {
  immediateLockbox = 'immediate lockbox',
  springingLockbox = 'springing lockbox',
  hardLockbox = 'hard lockbox',
  softLockbox = 'soft lockbox',
}

export enum penaltyTypeOptions {
  defeasance = 'defeasance',
  yieldMaintenance = 'yield maintenance',
  stepdown = 'stepdown',
  // flatPrePay = 'flat pre-pay',
  // minimumInterest = 'minimum interest',
  // otherOrLimited = 'other/limited',
}

export enum extensionsAmountOptions {
  one = '1',
  two = '2',
  three = '3',
  four = '4',
  five = '5',
}
