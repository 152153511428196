import {useSelector} from 'react-redux';
import {ReactComponent as LogoIcon} from 'shared/media/Logo.svg';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MenuIcon from '@mui/icons-material/Menu';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import {IconButton} from '@mui/material';
import {styled} from '@mui/material/styles';
import {aboutStackSource} from 'core/_consts';
import {useLocation, useParams} from 'react-router-dom';
import {URLParams} from 'core/_types/base';
import {selectorGetAva} from 'store/ava-service/selector';
import s from './Header.module.scss';

const StyledMenuIcon = styled(IconButton)({
  display: 'none',
  '@media(max-width: 768px)': {
    display: 'flex',
    padding: 0,
  },
});

const Header = () => {

  const location = useLocation();
  const params = useParams<URLParams>();
  const ava = useSelector(selectorGetAva);

  const exitUrl = ava.sharedUser.token ? null : `https://platform.stacksource.com/project:${params.projectId}/overview`;
  const oldUiUrl = `https://platform.stacksource.com/project:${params.projectId}/view${location.hash}${location.hash ? '&ui=0' : '#ui=0'}`;

  return (
    <div className={s.header}>
      <div className={s.header__container}>
        <div className={s.header__left}>
          <LogoIcon className={s.header__logo} />
          <p className={s.header__title}>StackSource</p>
        </div>
        <div className={s.header__right}>
          {/* <div className={s.learnMore}>
            <p>New to StackSource?&nbsp;</p>
            <a href={aboutStackSource} className="link fw600">
              Learn more
            </a>
          </div> */}
          {exitUrl ? (
            <div className={s.learnMore}>
              <ArrowBackIcon /> <a href={exitUrl}>Exit lender view</a>
            </div>
          ) : null}
          <div className={s.learnMore}>
            <WebAssetIcon /> <a href={oldUiUrl}>Switch to old UI</a>
          </div>
          <StyledMenuIcon size="large">
            <MenuIcon />
          </StyledMenuIcon>
        </div>
      </div>
    </div>
  );
};

export default Header;
