import {FC} from 'react';
import cx from 'classnames';
import useWindowWidth from 'core/utils/hooks/useWindowWidth';
import {THEMES} from 'shared/helpers';

import s from './TextArea.module.scss';

interface TextArea {
  width: string;
  height: string;
  onValueChange: (value: string) => void;
  isValid?: boolean;
  defaultValue?: string;
  label?: string;
  theme?: THEMES;
}

const TextArea: FC<TextArea> = ({width, height, onValueChange, isValid, defaultValue, label, theme}) => {
  const windowWidth = useWindowWidth();

  const inputWidth = windowWidth > 580 ? width : '100%';

  return (
    <>
      {label && <p className={s.textArea__label}>{label}</p>}
      <textarea
        onChange={(e) => onValueChange(e.target.value)}
        className={cx(s.textArea, {FormSlide__inputValid: isValid}, {[s.textArea__white]: theme === THEMES.white})}
        style={{width: inputWidth, height}}
        defaultValue={defaultValue}
      />
    </>
  );
};

export default TextArea;
