/* eslint-disable consistent-return */
import {createAsyncThunk} from '@reduxjs/toolkit';
import {toast} from 'react-toastify';
import {getCapitalAdvisorProfilesPropsType, getOrganizationsPropsType, CapitalAdvisorProfilesType, OrganizationsType, UserSettingsType} from './interfaces/index';

import 'react-toastify/dist/ReactToastify.css';

export const getCapitalAdvisorProfiles = createAsyncThunk(
  'user/getCapitalAdvisorProfiles',
  async ({usersSyms, ava}: getCapitalAdvisorProfilesPropsType): Promise<CapitalAdvisorProfilesType | void> => {
    try {
      const userProfiles = await Promise.all(usersSyms.map((item) => ava.getUserProfile(item)));
      return userProfiles;
    } catch (error: any) {
      // toast.error(`${error.message}`);
      if (console.error) {
        console.error(error && error.message);
      }
    }
  }
);

export const getOrganizations = createAsyncThunk(
  'user/getOrganizations',
  async ({orgSyms, ava}: getOrganizationsPropsType): Promise<OrganizationsType | void> => {
    try {
      console.log('getting orgs'); // DEBUG
      const orgs = await Promise.all(orgSyms.map((item: any) => ava.getOrganization(item)));
      console.log('orgs:', orgs, JSON.stringify(orgs)); // DEBUG
      return orgs;
    } catch (error: any) {
      // toast.error(`${error.message}`);
      if (console.error) {
        console.error(error && error.message);
        console.error(error && error.stack);
      }
      throw error;
    }
  }
);

export const getUserSettings = createAsyncThunk(
  'user/getUserSettings',
  async (ava: any): Promise<UserSettingsType | void> => {
    try {
      const userSettings = await ava.getUserSettings();
      return userSettings;
    } catch (error: any) {
      // toast.error(`${error.message}`);
      if (console.error) {
        console.error(error && error.message);
      }
    }
  }
);
