export enum DashboardViews {
  Cards,
  Table,
}

export enum ExpectedRepaymentSchedule {
  UNSET = 0,
  SELF_LIQUIDATING = 2,
  FULL_TERM_IO = 3,
  PARTIAL_TERM_IO = 4,
  FIFTEEN_YEARS = 5,
  TWENTY_YEARS = 6,
  TWENTY_FIVE_YEARS = 7,
  THIRTY_PLUS_YEARS = 8,
}

export enum ExpectedInterestRate {
  UNSET = 0,
  FIXED = 1,
  FLOATING = 2,
}

export enum ExpectedRecourse {
  UNSET = 0,
  NO_PREFERENCE = 2,
  PREFER_NON_RECOURSE = 3,
  REQUIRE_NON_RECOURSE = 1,
}

export enum ProFormaLowestValue {
  CAP_RATE_VALUATION = 0,
  STABILIZED_CAP_RATE_VALUATION = 1,
}
