import {configureStore} from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import projectReducer from './project-service/projectSlice';
import userReducer from './user-service/userProfilesSlice';
import orgReducer from './user-service/orgSlice';
import avaReducer from './ava-service/avaSlice';
import quoteTermsReducer from './quote-terms-service/quoteTermsSlice';

const store = configureStore({
  reducer: {
    projectRedux: projectReducer,
    quoteTermsRedux: quoteTermsReducer,
    userRedux: userReducer,
    orgRedux: orgReducer,
    avaRedux: avaReducer,
  },
  middleware: [thunk],
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
