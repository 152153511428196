/* eslint-disable consistent-return */
import {createAsyncThunk} from '@reduxjs/toolkit';
import {toast} from 'react-toastify';
import {getProjectPropsType, ProjectType} from './interfaces';
import 'react-toastify/dist/ReactToastify.css';

export const getProject = createAsyncThunk(
  'project/getProject',
  async ({projectSym, ava}: getProjectPropsType): Promise<ProjectType | void> => {
    try {
      const page = document.getElementsByClassName('LenderView_lenderView__wrapper__2Z1J4')[0];
      if (page) {
        (page as any).style.display = 'none'; // HACK
      }
      const project = await ava.getProject(projectSym);
      if (page) {
        (page as any).style.display = 'flex'; // HACK
      }
      return project;
    } catch (e: any) {
      // toast.error(`${e.message}`);
      if (console.error) {
        console.error(e && e.message);
      }
    }
  }
);
