import {FC, useState, useEffect} from 'react';
import {Divider} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {useHistory, Link, matchPath, useLocation, useParams} from 'react-router-dom';
import {URLParams} from 'core/_types/base';
import s from './NonBindingOffer.module.scss';

const useStyles = makeStyles({
  hint: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '18px',
    height: '18px',
    borderRadius: '50%',
    backgroundColor: '#b3d5f8',
    color: 'white',
    cursor: 'default',
  },
});

const SimpleNonBindingOffer = () => {
  const location = useLocation();
  const params = useParams<URLParams>();
  const url = `https://platform.stacksource.com/project:${params.projectId}/view${location.hash}${location.hash ? '&ui=0' : '#ui=0'}`;
  return (
    <div className={s.nonBindingOffer}>
      {'You have submitted a non-binding offer. '}
      <a href={url}>View or modify your offer</a>
    </div>
  );
};

export default SimpleNonBindingOffer;
