import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {getProject} from './asyncActions';
import {IProjectReducer, ProjectType} from './interfaces';

const initialState: IProjectReducer = {
  project: {},
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProject.fulfilled, (state, action: PayloadAction<ProjectType>) => {
      // eslint-disable-next-line no-param-reassign
      state.project = action.payload;
    });
  },
});

export default projectSlice.reducer;
