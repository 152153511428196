import {FC} from 'react';
import cx from 'classnames';
import s from './SelectionCard.module.scss';

type SelectionCardProps = {
  selectedCard: string;
  setSelectedCard: (value: string) => void;
  iconName: string;
  title: string;
};

const SelectionCard: FC<SelectionCardProps> = ({iconName, title, selectedCard, setSelectedCard}) => (
  <div
    className={cx(s.selectionCard, {[s.selectionCard__selected]: selectedCard === title})}
    onClick={() => setSelectedCard(title)}
  >
    <div className={s.selectionCard__top}>
      <img className={s.selectionCard__icon} src={`/images/createNewOffer/${iconName}.svg`} alt="icon" />
    </div>
    <p>{title}</p>
  </div>
);

export default SelectionCard;
