import {FC, useMemo} from 'react';
import cx from 'classnames';
// TODO: uncomment after getting response about disclaimers
import {ReactComponent as DisclaimerIcon} from 'shared/media/Disclaimer.svg';
import {useSelector} from 'react-redux';
import Ava from 'core/service/index.js';
import {selectorGetProject} from 'store/project-service/selector';
import DataCell, {DataCellProps} from 'shared/components/DataCell/DataCell';
import AccordionWrapper from 'shared/components/Accordion';
import s from './Sponsor.module.scss';
import {IsMobileViewProps} from '../helpers';

const filterValuesByBoolean = (values: Array<DataCellProps>) => {
  console.log('values:', values); // DEBUG
  if (!values) return false;
  return values.map((item) => (item.isTruthy ?? item.data) && item.title !== 'Sponsor Type').some(Boolean);
};

const Sponsor: FC<IsMobileViewProps> = ({isMobileView}) => {
  const project = useSelector(selectorGetProject);

  const sponsorTableData = useMemo(
    () => [
      {title: 'Year Founded', data: project.sponsorProfile?.yearFounded},
      {title: 'Years Of Experience', data: project.sponsorProfile?.yearsOfExperience},
      {
        title: 'Address',
        data: project.sponsorProfile?.address.formatCityAndState(),
        isTruthy: !project.sponsorProfile?.address.isEmpty(),
      },
      {
        title: 'Assets Under Ownership',
        data: project.sponsorProfile?.assetsUnderOwnership.format(),
        isTruthy: !project.sponsorProfile?.assetsUnderOwnership.isZero(),
      },
      {title: 'Sponsor Type', data: project.sponsorProfile?.formatSponsorType()},
    ],
    [project]
  );

  const disclaimers = [
    {
      value: project?.disclaimer.majorCrimes,
      ifYes: 'A major crime was commited on the property in the last 5 years.',
      ifNo: 'A major crime was not commited on the property in the last 5 years.',
    },
    {
      value: project?.disclaimer.lesserCrimes,
      ifYes: 'There is a persistent pattern of lesser crimes commited on the property.',
      ifNo: 'There is not a persistent pattern of lesser crimes commited on the property.',
    },
    {
      value: project?.disclaimer.taxesDelinquent,
      ifYes: 'The property is not current on all taxes.',
      ifNo: 'The property is current on all taxes.',
    },
    {
      value: project?.disclaimer.paymentsDelinquent,
      ifYes: 'The property is not current on existing mortgage payments.',
      ifNo: 'The property is current on existing mortgage payments.',
    },
    {
      value: project?.disclaimer.environmentalHazards,
      ifYes: 'The property has environmental hazard issues.',
      ifNo: 'The property does not have environmental hazard issues.',
    },
    {
      value: project?.disclaimer.floodZone,
      ifYes: 'The property is located in a flood zone.',
      ifNo: 'The property is not located in a flood zone.',
    },
    {
      value: project?.disclaimer.seismicZone,
      ifYes: 'The property is located in a high risk seismic zone.',
      ifNo: 'The property is not located in a high risk seismic zone.',
    },
    {
      value: project?.disclaimer.sponsorLawsuits,
      ifYes: 'Has been involved in any past or pending lawsuits, judgements, liens, foreclosures or bankruptcies',
      ifNo: 'Has not been involved in any past or pending lawsuits, judgements, liens, foreclosures or bankruptcies',
    },
    {
      value: project?.disclaimer.sponsorFelony,
      ifYes: 'Has been charged or convicted of a felony',
      ifNo: 'Has not been charged or convicted of a felony',
    },
  ]
    // 0 is UNSET, 1 is YES, and 2 is NO
    .filter(({value, ifYes, ifNo}) => value !== 0)
    .map(({value, ifYes, ifNo}: any) => value === 1 ? ifYes : ifNo);

  const content = (
    <div className="LenderView__section">
      {!isMobileView && <p className="LenderView__infoContentTitle">Sponsor</p>}
      {project.sponsorProfile?.description.isEmpty() ? null : (
        <div style={{marginBottom: '20px'}} dangerouslySetInnerHTML={{__html: project.sponsorProfile.description.formatAsHtml()}} />
      )}
      <div className={cx(s.table, 'LenderView__flexTable')}>
        <div className={s.table__mainInfo}>
          <div className="fw600">{project.sponsorProfile?.name}</div>
          <a target="_blank" href={project.sponsorProfile?.website} className={cx('link', 'fw600')}>
            {project.sponsorProfile?.website && hostname(project.sponsorProfile.website)}
          </a>
        </div>
        {sponsorTableData?.map((item) => (
          <DataCell key={item.title} data={item.data} title={item.title} isTruthy={item.isTruthy} />
        ))}
      </div>
      {disclaimers.length > 0 ? (
        <div className={s.disclaimer}>
          <p className={s.disclaimer__title}>Disclaimer</p>
          <div className={s.disclaimer__list}>
          {disclaimers.map((item: any) => (
                <div className={s.disclaimer__item} key={item}>
                  <DisclaimerIcon className={s.disclaimer__icon} />
                  <p>{item}</p>
                </div>
              ))}
          </div>
        </div>
      ) : null}
    </div>
  );

  if (
    !filterValuesByBoolean(sponsorTableData) &&
    disclaimers.length === 0 &&
    project.sponsorProfile?.description.isEmpty()
  ) return null;
  if (isMobileView) return <AccordionWrapper title="Sponsor">{content}</AccordionWrapper>;

  return content;
};

export default Sponsor;

function hostname(website: string) {
  try {
    const url = new URL(website);
    return url.hostname;
  } catch (e) {
    console.log(website);
    return '';
  }
}

