import {FC, useEffect, useState, useRef, useMemo, useCallback} from 'react';
import {useSelector} from 'react-redux';
import cx from 'classnames';
import {Skeleton} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {selectorGetProject} from 'store/project-service/selector';

import styles from './PhotoSlider.module.scss';

type PhotoSliderProps = {
  images: Array<string>;
  initialActiveItem?: number;
};

const useStyles = makeStyles({
  skeleton: {
    borderRadius: '10px',
  },
});

const isSliderFullSize = (initialActiveItem: number | undefined) => initialActiveItem || initialActiveItem === 0;

let curImages: string[] = [];
const PhotoSlider: FC<PhotoSliderProps> = ({images, initialActiveItem}) => {
  const [loadedImages, setLoadedImages] = useState<Array<number>>([]);
  const initialParameters = useMemo(
    () =>
      initialActiveItem === undefined
        ? {maxVisibleDotsNumber: 5, dotWidth: 24, centerElement: 2}
        : {maxVisibleDotsNumber: 8, dotWidth: 38, centerElement: 4},
    []
  );

  const classes = useStyles();

  const [active, setActive] = useState(initialActiveItem ?? 0);
  const interval = useRef<number>();

  curImages = images;
  const setWindowInterval = () => {
    interval.current = window.setInterval(() => {
      setActive((prev) => (prev >= curImages.length - 1 ? 0 : prev + 1));
    }, 5000);
  };

  const calcTranslate = useCallback(
    () =>
      active >= images.length - initialParameters.centerElement
        ? initialParameters.dotWidth * (images.length - initialParameters.maxVisibleDotsNumber)
        : initialParameters.dotWidth * (active - initialParameters.centerElement),
    [active]
  );

  const handleClick = (value: number) => {
    window.clearInterval(interval.current);
    setActive(value);
    setWindowInterval();
  };

  const isAllImagesLoaded = loadedImages.length === images.length;

  useEffect(() => {
    if (isAllImagesLoaded) setWindowInterval();
    return () => {
      window.clearInterval(interval.current);
    };
  }, [isAllImagesLoaded]);

  const project = useSelector(selectorGetProject);
  const address = encodeURIComponent(project.address?.formatOneLine() || '');

  return (
    <div
      className={cx(styles.Slider, {
        [styles.fullSize]: isSliderFullSize(initialActiveItem),
      })}
    >
      {!isAllImagesLoaded && (
        <div className="LenderView__loader">
          <Skeleton variant="rectangular" className={classes.skeleton} width="100%" height="100%" />
        </div>
      )}
      <div
        className={cx(styles.Slider__arrowWrapper, styles.Slider__arrowWrapper_left, {hidden: images.length < 2})}
        onClick={() => handleClick(active === 0 ? images.length - 1 : active - 1)}
      >
        <div
          className={cx(styles.Slider__arrow, styles.Slider__arrow_back, {
            [styles.fullSize]: isSliderFullSize(initialActiveItem),
          })}
        />
      </div>
      {images.map((imageUrl, index) => (
        <img
          key={imageUrl}
          className={cx(styles.Slider__image, {
            [styles.active]: index === active,
          })}
          src={
            imageUrl === ''
              ? `https://maps.googleapis.com/maps/api/staticmap?center=${address}&zoom=13&size=490x300&maptype=roadmap&markers=${address}&key=AIzaSyCRKEp1iM16gumouiXM2vSs6D7eYPcQN_s`
              : imageUrl
          }
          alt="Gallery item"
          onLoad={() => setLoadedImages([...loadedImages, index])}
        />
      ))}
      <div
        className={cx(styles.Slider__arrowWrapper, styles.Slider__arrowWrapper_right, {hidden: images.length < 2})}
        onClick={() => handleClick(active === images.length - 1 ? 0 : active + 1)}
      >
        <div
          className={cx(styles.Slider__arrow, styles.Slider__arrow_forward, {
            [styles.fullSize]: isSliderFullSize(initialActiveItem),
          })}
        />
      </div>
      <div
        className={cx(styles.Slider__dotsWrapper, {
          'no-print': true,
          [styles.fullSize]: isSliderFullSize(initialActiveItem),
        })}
      >
        <div
          style={{
            transform:
              images.length > initialParameters.maxVisibleDotsNumber && active > initialParameters.centerElement
                ? `translateX(-${calcTranslate()}px)`
                : '',
          }}
          className={cx(styles.Slider__dots, {
            [styles.center]: images.length < initialParameters.maxVisibleDotsNumber,
          })}
        >
          {images.map((imageUrl, index) => (
            <div
              key={imageUrl}
              className={cx(styles.Slider__dot, {
                [styles.active]: index === active,
                [styles.fullSize]: isSliderFullSize(initialActiveItem),
              })}
              onClick={() => handleClick(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PhotoSlider;
