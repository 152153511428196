import {FC, useState, useEffect, useLayoutEffect} from 'react';
import {useDispatch} from 'react-redux';
import {IconButton} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {LoanAmountSubsteps, QuoteTermsSteps, QuoteTermsStepTitles, ROUTERS, storageKey} from 'core/_consts';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import cx from 'classnames';
import BasicButton from 'shared/components/BasicButton';
import {vaultService} from 'core/service/storage';
import {quoteTermsStorageType} from 'core/_types/quoteTermsTypes';
import {setInitialValues} from 'store/quote-terms-service/quoteTermsSlice';
import {QuoteTermsInitialValues} from 'core/utils/quoteTermsValues';
import {URLParams} from 'core/_types/base';
import {queryParams} from 'core/utils';
import {THEMES} from 'shared/helpers';
import StepSlider from 'shared/components/StepSlider';
import LoanAmount from './Steps/LoanAmount';
import s from './QuoteTermsWrapper.module.scss';
import LoanTerm from './Steps/LoanTerm';
import InterestRate from './Steps/InterestRate';
import Amortization from './Steps/Amortization';
import Recourse from './Steps/Recourse';
import InitialDeposit from './Steps/InitialDeposit';
import LoanFees from './Steps/LoanFees';
import RequiredReserves from './Steps/RequiredReserves';
import CashManagament from './Steps/CashManagament';
import BorrowerStipulations from './Steps/BorrowerStipulations';
import OtherRequirements from './Steps/OtherRequirements';
import PrepayPenalty from './Steps/PrepayPenalty';

const useStyles = makeStyles({
  button: {
    position: 'absolute',
    bottom: 0,
    padding: 0,
    color: '#848484',
    font: '600 18px Source Sans Pro',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '@media(max-width: 768px)': {
      display: 'none',
    },
  },
  backButton: {
    left: 0,
  },
  cancelButton: {
    right: 0,
  },
  closeButton: {
    display: 'none',
    '@media(max-width: 768px)': {
      position: 'absolute',
      right: 0,
      display: 'flex',
      padding: 0,
    },
  },
});

type QuoteTermsStepsKeys = keyof typeof QuoteTermsSteps;

const QuoteTermsWrapper: FC = () => {
  const history = useHistory();
  const location = useLocation();
  const params = useParams<URLParams>();

  const [step, setStep] = useState<QuoteTermsStepsKeys | null>(
    new URLSearchParams(location.search).get('step') as QuoteTermsStepsKeys
  );

  const dispatch = useDispatch();

  const [isStartPoint, setIsStartPoint] = useState(false);

  const setStoreFromLocalStorage = () => {
    const data = vaultService.getItem<quoteTermsStorageType>(storageKey.quoteTermsData);
    const state = new QuoteTermsInitialValues(data);
    dispatch(setInitialValues(state));
  };

  useEffect(() => {
    setStoreFromLocalStorage();
  }, []);

  useLayoutEffect(() => {
    const currentStep = new URLSearchParams(location.search).get('step') as QuoteTermsStepsKeys;
    setStep(currentStep);
    setIsStartPoint(
      new URLSearchParams(location.search).get('substep') ===
        LoanAmountSubsteps[LoanAmountSubsteps.loanSizingConstraints]
    );
  }, [location.search]);

  const renderStep = () => {
    switch (step) {
      case QuoteTermsSteps[QuoteTermsSteps.loanAmount]:
        return <LoanAmount />;

      case QuoteTermsSteps[QuoteTermsSteps.loanTerm]:
        return <LoanTerm />;

      case QuoteTermsSteps[QuoteTermsSteps.interestRate]:
        return <InterestRate />;

      case QuoteTermsSteps[QuoteTermsSteps.amortization]:
        return <Amortization />;

      case QuoteTermsSteps[QuoteTermsSteps.recourse]:
        return <Recourse />;

      case QuoteTermsSteps[QuoteTermsSteps.prepayPenalty]:
        return <PrepayPenalty />;

      case QuoteTermsSteps[QuoteTermsSteps.initialDeposit]:
        return <InitialDeposit />;

      case QuoteTermsSteps[QuoteTermsSteps.loanFees]:
        return <LoanFees />;

      case QuoteTermsSteps[QuoteTermsSteps.requiredReserves]:
        return <RequiredReserves />;

      case QuoteTermsSteps[QuoteTermsSteps.cashManagement]:
        return <CashManagament />;

      case QuoteTermsSteps[QuoteTermsSteps.borrowerStipulations]:
        return <BorrowerStipulations />;

      case QuoteTermsSteps[QuoteTermsSteps.otherRequirements]:
        return <OtherRequirements />;

      default:
        return null;
    }
  };

  const closeForm = () => history.push(`${ROUTERS.PROJECT}:${params.projectId}/view${queryParams({})}${location.hash}`);

  const classes = useStyles();

  return (
    <div className={s.quoteTermsWrapper}>
      <div className={s.quoteTermsWrapper__header}>
        <p className="LenderView__infoContentTitle">Quote Terms</p>
        <p className={s.quoteTermsWrapper__sheetType}>Non-binding Offer Sheet</p>
        <IconButton className={classes.closeButton} disableRipple onClick={closeForm}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={s.quoteTermsWrapper__content}>{renderStep()}</div>
      <div className={s.quoteTermsWrapper__footer}>
        {!isStartPoint && (
          <IconButton
            className={cx(classes.button, classes.backButton)}
            disableRipple
            onClick={() => history.go(-1)}
            size="small"
          >
            <ArrowBackIcon />
            <span>Back</span>
          </IconButton>
        )}
        <div className={s.quoteTermsWrapper__sliderWrapper}>
          {step && (
            <StepSlider
              titles={Object.values(QuoteTermsStepTitles).filter((item) => typeof item !== 'number') as Array<string>}
              theme={THEMES.blue}
              steps={Object.values(QuoteTermsSteps)}
              currentStep={QuoteTermsSteps[step]}
            />
          )}
        </div>
        <BasicButton className={cx(classes.button, classes.cancelButton)} disableRipple onClick={closeForm}>
          <span>Cancel</span>
        </BasicButton>
      </div>
    </div>
  );
};

export default QuoteTermsWrapper;
