import SelectionCard from 'pages/CreateNewOffer/SelectionCard';
import {useState} from 'react';
import s from './Second.module.scss';

const iconTitles = [
  'Apartments',
  'Condominiums',
  'Senior Apartments',
  'Student Housing',
  'Assisted Living',
  'Rental Portfolio',
  'Mobile Home Park',
  'Co-Living',
];

const Second = () => {
  const [selectedCard, setSelectedCard] = useState('');

  return (
    <div className={s.second}>
      <div className={s.second__header}>
        <p className={s.second__title}>What type of multi-family property?</p>
      </div>
      <div className={s.second__cards}>
        {iconTitles.map((iconTitle) => (
          <SelectionCard
            key={iconTitle}
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
            title={iconTitle}
            iconName={iconTitle.toLocaleLowerCase()}
          />
        ))}
      </div>
    </div>
  );
};

export {Second};
