import {FC, useState} from 'react';
import RadioGroup from 'shared/components/RadioGroup';
import {Formik, Form, FormikErrors} from 'formik';
import {IRateDetailsSlideData, quoteTermsStorageType} from 'core/_types/quoteTermsTypes';
import {rateDetailsSchema} from 'core/helpers/yupSchemas';
import NextButton from 'shared/components/NextButton';
import {setRateDetails} from 'store/quote-terms-service/quoteTermsSlice';
import {rateDetailsOptions, InterestRateSubsteps, QuoteTermsSteps, storageKey} from 'core/_consts';
import {vaultService} from 'core/service/storage';
import {useSelector} from 'react-redux';
import {selectRateDetails} from 'store/quote-terms-service/quoteTermsSelector';
import cx from 'classnames';
import BasicButton from 'shared/components/BasicButton';
import FixedSpread from './FixedSpread';
import Floating from './Floating';
import FixedPercent from './FixedPercent';

const rateDetailsOptionsValues: Array<string> = Object.values(rateDetailsOptions);

const RateDetails: FC = () => {
  const [currentReset, setCurrentReset] = useState(0);

  const rateDetails: IRateDetailsSlideData[] = useSelector(selectRateDetails);
  const defaultValue = rateDetails;
  // {
  //   selectedRateDetails: [],
  //   fixedPercent: '',
  //   fixedSpread: {
  //     index: '',
  //     spread: '',
  //     fixedAt: '',
  //     rateFloor: '',
  //     indexFloor: '',
  //   },
  //   floating: {
  //     index: '',
  //     spread: '',
  //     rateFloor: '',
  //     indexFloor: '',
  //   },
  // };

  const quoteTermsData = vaultService.getItem<quoteTermsStorageType>(storageKey.quoteTermsData);
  const resets: string[] = (quoteTermsData?.rateResets[0] === 'yes' && quoteTermsData?.resetYear) || [];

  const [isAnyDetailSelected, setIsAnyDetailSelected] = useState<Array<Array<string>>>(
    resets.map((u, i) => rateDetails[i]?.selectedRateDetails || [])
  );

  const resetYear = resets.length === 0 ? '' : resets[currentReset];

  const selectedSections = isAnyDetailSelected.map(
    (u) => Object.keys(rateDetailsOptions)[rateDetailsOptionsValues.indexOf(u[0])]
  );
  console.log('DEBUG:', resets, isAnyDetailSelected, selectedSections);

  const getSlideData = (values: any) => ({
    [InterestRateSubsteps[InterestRateSubsteps.rateDetails]]: selectedSections.map((selectedSection, i) => ({
      [selectedSection]: values[i] ? values[i][selectedSection as keyof typeof values] : {},
    })),
  });

  const renderTerm = (year: string, index: number) => (
    <div style={{maxWidth: '470px', margin: '50px auto 0'}}>
      {year ? (
        <div className={cx('FormSlide__title_highlighted')} style={{margin: '20px 0 20px', fontSize: '120%'}}>
          {year === 'initial' ? 'for the initial term' : `for the term beginning ${year}`}
        </div>
      ) : null}
      <div style={{margin: '20px 0'}}>
        <RadioGroup
          isAnyValueSelected={isAnyDetailSelected[index] || []}
          setIsAnyValueSelected={(value) =>
            setIsAnyDetailSelected([
              ...isAnyDetailSelected.slice(0, index),
              [value[0]],
              ...isAnyDetailSelected.slice(index + 1),
            ])
          }
          radioButtonTitles={rateDetailsOptionsValues}
        />
      </div>
    </div>
  );

  const renderSection = (index: number) => {
    const currentSection = isAnyDetailSelected[index] && isAnyDetailSelected[index][0];
    switch (currentSection) {
      case rateDetailsOptions.fixedPercent:
        return (
          <div style={{maxWidth: '120px'}}>
            <FixedPercent index={index} />
          </div>
        );
      case rateDetailsOptions.fixedSpread:
        return <FixedSpread index={index} />;
      case rateDetailsOptions.floating:
        return <Floating index={index} />;
      default:
        return null;
    }
  };

  const renderNextButton = (
    errors: FormikErrors<IRateDetailsSlideData[]>,
    values: IRateDetailsSlideData[],
    isDisabled: boolean,
    isHidden: boolean
  ) => (
    <NextButton
      isDisabled={isAnyDetailSelected.length <= resets.length}
      slideData={getSlideData(values)}
      action={() => setRateDetails(values)}
      nextSlide={{
        step: QuoteTermsSteps[QuoteTermsSteps.interestRate],
        substep: InterestRateSubsteps[InterestRateSubsteps.rateCalculation],
      }}
    />
  );

  return (
    <div className="FormSlide">
      <div className="FormSlide__heading mb35">
        <p className="FormSlide__title">Enter the interest rate details.</p>
        <p className="FormSlide__subTitle">You’ll be prompted to provide additional information.</p>
      </div>
      <div className="FormSlide__content" style={{marginTop: '-50px'}}>
        <Formik
          initialValues={defaultValue}
          validateOnMount
          enableReinitialize
          validationSchema={rateDetailsSchema}
          // TODO: add handle logic on submit
          // eslint-disable-next-line no-console
          onSubmit={(values) => console.log(values)}
        >
          {({errors, values}) => (
            <Form className="FormSlide__form">
              {resets.length === 0 ? (
                <>
                  {renderTerm('', 0)}
                  {renderSection(0)}
                  {renderNextButton(errors, values, false, false)}
                </>
              ) : (
                ['initial', ...resets].map((reset, index) => (
                  <div key={`key-${'ABCDEFG'[index]}`}>
                    {renderTerm(reset, index)}
                    {renderSection(index)}
                  </div>
                ))
              )}
              {renderNextButton(errors, values, false, false)}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default RateDetails;
