import {FC, useMemo} from 'react';
import cx from 'classnames';
import {FieldInputProps, FieldMetaProps} from 'formik';
import s from './TextInput.module.scss';

interface ITextInputProps {
  width: string;
  onValueChange?: (value: string) => void;
  textAlign?: 'left' | 'center' | 'right';
  isValid?: boolean;
  field?: FieldInputProps<any>;
  meta?: FieldMetaProps<any>;
  defaultValue?: string;
  label?: string;
  maxLength?: number;
  fullWidth?: boolean;
}

const TextInput: FC<ITextInputProps> = ({
  width,
  defaultValue,
  isValid,
  onValueChange,
  maxLength,
  fullWidth,
  field,
  meta,
  label,
  textAlign = 'left',
}) => {
  const responsiveInputWidth = useMemo(() => (fullWidth ? '100%' : width), [fullWidth, width]);

  return (
    <div className={cx(s.textInput, {fullWidth})}>
      {label && <p className="FormSlide__inputLabel">{label}</p>}
      <input
        {...field}
        defaultValue={defaultValue}
        onChange={(e) => field?.onChange(e) || onValueChange?.(e.target.value)}
        className={cx(s.textInput__item, {inputValid: isValid ?? (!meta?.error && (meta?.touched || field?.value))})}
        style={{width: responsiveInputWidth, textAlign}}
        maxLength={maxLength}
      />
    </div>
  );
};
export default TextInput;
