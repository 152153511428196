import React, {FC, useEffect, useState} from 'react';
import cx from 'classnames';
import {useSelector} from 'react-redux';
import {selectorGetProject} from 'store/project-service/selector';
import AccordionWrapper from 'shared/components/Accordion';
import s from './Guarantor.module.scss';
import {IsMobileViewProps} from '../helpers';

const Guarantor: FC<IsMobileViewProps> = ({isMobileView}) => {
  const project = useSelector(selectorGetProject);
  const [guarantors, setGuarantors] = useState([]);

  useEffect(() => {
    if (Object.getOwnPropertyNames(project).length) setGuarantors(Array.from(project.guarantorList));
  }, [project]);

  const content = (
    <div className={cx(s.guarantor, 'LenderView__section')}>
      {!isMobileView && <p className="LenderView__infoContentTitle">Guarantor</p>}
      {guarantors.map((item: any, i: number) => (
        <div style={i < guarantors.length - 1 ? {marginBottom: '18px'} : {}} key={item.name}>
          <p className="fw600">{item.name}</p>
          {/* TODO: ask for more details about this info */}
          {item.address.isEmpty() ? null : <p>{item.address.formatCityAndState()}</p>}
          <p>
            {item.entityType === 0 ? 'Individual'
              : item.entityType === 1 ? 'LLC'
              : item.entityType === 2 ? 'Corporation'
              : '' // unexpected
            }
            {item.entityType !== 0 ? null : (
              item.usCitizenship === 0 ? ''
              : item.usCitizenship === 1 ? ', US Citizen'
              : item.usCitizenship === 2 ? ', Immigrant in US legally'
              : item.usCitizenship === 3 ? ', Non-immigrant with visa'
              : '' // unexpected
            )}
            {item.netWorth.isZero() ? null : <p>Net Worth: {item.netWorth.format()}</p>}
            {item.liquidity.isZero() ? null : <p>Liquidity: {item.liquidity.format()}</p>}
            {item.entityType !== 0 || item.creditScore === 0 ? null : <p>Credit Score: {
              item.creditScore === 1 ? 'Bad (499 or below)'
              : item.creditScore === 2 ? 'Poor (500 - 599)'
              : item.creditScore === 3 ? 'Okay (600 - 649)'
              : item.creditScore === 4 ? 'Good (650 - 679)'
              : item.creditScore === 5 ? 'Great (680 - 719)'
              : item.creditScore === 6 ? 'Excellent (720 or above)'
              : '' // unexpected
            }</p>}
          </p>
        </div>
      ))}
    </div>
  );

  if (!guarantors.length) return null;
  if (isMobileView) return <AccordionWrapper title="Guarantor">{content}</AccordionWrapper>;

  return content;
};

export default Guarantor;
