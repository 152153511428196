import SelectInput from 'shared/components/SelectInput';
import {useState} from 'react';
import {THEMES} from 'shared/helpers';
import {MaxValidationLimits} from 'core/_consts';
import NumericInput from 'shared/components/NumericInput';
import {validateNumericInput} from 'core/utils';
import {useSelector} from 'react-redux';
import {selectDollarLoanAmount} from 'store/quote-terms-service/quoteTermsSelector';

import s from './Sixth.module.scss';

const selectOptionsMock = ['Property Acquisition', 'CapEx', 'Status 3'];

const Sixth = () => {
  const defaultValue = useSelector(selectDollarLoanAmount);
  const [dollarAmountValue, setDollarAmountValue] = useState(Number(defaultValue) || '');

  return (
    <div className={s.sixth}>
      <div className={s.sixth__header}>
        <p className={s.sixth__title}>Project Budget</p>
      </div>
      <div className={s.sixth__inputs}>
        <SelectInput label="Uses*" placeholder="Select Category" theme={THEMES.white} options={selectOptionsMock} />
        <div>
          <label htmlFor="label" className={s.sixth__label}>
            Amount*
          </label>
          <NumericInput
            theme={THEMES.white}
            color={THEMES.white}
            defaultValue={dollarAmountValue}
            onValueChange={(values) => setDollarAmountValue(Number(values.value))}
            thousandSeparator
            isValid={validateNumericInput(Number(dollarAmountValue), MaxValidationLimits.highestLimit)}
            width="300px"
            preventionLimit={MaxValidationLimits.highestLimit}
            border="1px solid white"
            placeholder="$"
            textAlign="left"
            background="none"
          />
        </div>
      </div>
      <div className={s.sixth__totalWrapper}>
        <p className={s.sixth__total}>Total: $0</p>
        <div className={s.sixth__add}>
          <span className={s.sixth__addIcon}>+</span>
          <p className={s.sixth__addItem}>Add an item</p>
        </div>
      </div>
    </div>
  );
};

export {Sixth};
