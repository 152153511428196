import {FC} from 'react';
import s from './Graph.module.scss';

type GraphProps = {
  title: string;
  totalBudget?: string;
  stabilizedValue?: string;
  ltc?: number;
  lowestValueProportions?: number;
  ltv?: number;
};

const Graph: FC<GraphProps> = ({title, totalBudget, stabilizedValue, ltc, lowestValueProportions, ltv}) => (
  <div className={s.graph}>
    <p className={s.graph__title}>{title}</p>
    <div className={s.graph__wrapper}>
      <div style={{height: `${lowestValueProportions}%`}} className={s.graph__content}>
        <div className={s.graph__top}>
          <p className={s.graph__budget}>{totalBudget || stabilizedValue}</p>
          <p>{totalBudget ? 'Total Project Budget' : 'Stabilized Value'}</p>
        </div>
        <div style={{height: `${ltc || ltv}`}} className={s.graph__bottom}>
          <p className={s.graph__percent}>{ltc || ltv}</p>
          <p>{ltc ? 'LTC' : 'LTV'}</p>
        </div>
      </div>
    </div>
  </div>
);
export default Graph;
