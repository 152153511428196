import {Button} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

const BasicButton = withStyles({
  root: {
    textTransform: 'none',
    fontWeight: 'bold',
    boxShadow: 'none',
    '&.white': {
      backgroundColor: 'white',
      color: '#5c6166',
      border: '1px solid #b4b4b4',
    },
    '&.blue': {
      backgroundColor: '#1c81ff',
      color: 'white',
    },
    '&.grey': {
      backgroundColor: '#848484',
      color: 'white',
    },
    '&.hidden': {
      visibility: 'hidden',
    },
    '&.nextButton': {
      width: '140px',
      '@media(max-width: 375px)': {
        width: '100%',
      },
    },
  },
})(Button);

export default BasicButton;
