/* eslint-disable no-param-reassign */
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {getCapitalAdvisorProfiles, getUserSettings} from './asyncActions';
import {IUserProfilesReducer, CapitalAdvisorProfilesType, UserSettingsType} from './interfaces';

const initialState: IUserProfilesReducer = {
  capitalAdvisorProfiles: [],
  userSettings: {},
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCapitalAdvisorProfiles.fulfilled, (state, action: PayloadAction<CapitalAdvisorProfilesType>) => {
      state.capitalAdvisorProfiles = action.payload;
    });
    builder.addCase(getUserSettings.fulfilled, (state, action: PayloadAction<UserSettingsType>) => {
      state.userSettings = action.payload;
    });
  },
});

export default userSlice.reducer;
