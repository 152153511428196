import {useState} from 'react';
import {Slider, Box} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import {THEMES} from 'shared/helpers';
import SelectInput from 'shared/components/SelectInput';
import TextArea from 'shared/components/TextArea';

import s from './Eighth.module.scss';

const selectOptionsMock = ['Option 1', 'Option 2', 'Option 3'];

const useStyles = makeStyles({
  colorPrimary: {
    color: '#fff',
  },
});

const Eighth = () => {
  const [notes, setNotes] = useState('');
  const [years, setYears] = useState<number[]>([5, 100]);

  const classes = useStyles();

  const handleRangeChange = (event: Event, newValue: number | number[]) => {
    setYears(newValue as number[]);
  };

  return (
    <div className={s.eighth}>
      <div className={s.eighth__header}>
        <p className={s.eighth__title}>Financing Request Details</p>
        <p className={s.eighth__subtitle}>
          Total Project Budget: <span className={s.eighth__budget}>$800,000</span>
        </p>
      </div>
      <div className={s.eighth__inputsContainer}>
        <div className={s.eighth__selects}>
          <SelectInput label="Loan Term*" width="300px" theme={THEMES.white} options={selectOptionsMock} require />
          <SelectInput
            label="Amortization"
            width="300px"
            labelTooltip={
              <div>
                <p className={s.eighth__tooltipBig}>Self Liquidating</p>
                <p className={s.eighth__tooltipSmall}>The amortization matches the loan term.</p>
                <p className={s.eighth__tooltipBig}>I/O</p>
                <p className={s.eighth__tooltipSmall}>
                  Interest only, a period when you are paying interest but not paying down the principal balance of your
                  loan.
                </p>
              </div>
            }
            theme={THEMES.white}
            options={selectOptionsMock}
          />
        </div>
        <div className={s.eighth__range}>
          <p className={s.eighth__years}>
            {years[0]} to {years[1]} years
          </p>
          <Box width={225} sx={{display: 'flex', alignItems: 'center'}}>
            <Slider classes={classes} size="small" value={years} onChange={handleRangeChange} />
          </Box>
        </div>
        <div className={s.eighth__selects}>
          <SelectInput
            label="Recourse"
            labelTooltip={
              <div>
                <p className={s.eighth__tooltipSmall}>Resource means being personally liable for the loan amount.</p>
                <a className={s.eighth__tooltipLink} href="/" target="_blank">
                  Learn more
                </a>
              </div>
            }
            theme={THEMES.white}
            options={selectOptionsMock}
          />
          <SelectInput label="Interest Rate" theme={THEMES.white} options={selectOptionsMock} />
        </div>
        <div className={s.eighth__textarea}>
          <TextArea
            width="620px"
            height="82px"
            onValueChange={setNotes}
            label="Financing Expectations & Notes"
            theme={THEMES.white}
          />
        </div>
      </div>
    </div>
  );
};

export {Eighth};
