import {createGoogleMapsLink} from 'core/utils';
import {googleMapsBaseUrl} from 'core/_consts';
import {FC, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {selectorGetProject} from 'store/project-service/selector';
import s from './ProjectNameHeader.module.scss';

const ProjectNameHeader: FC = () => {
  const project = useSelector(selectorGetProject);
  const [adressLink, setAdressLink] = useState('');

  useEffect(() => {
    if (project.address) setAdressLink(createGoogleMapsLink(project.address));
  }, [project]);

  return (
    <div className={s.projectNameHeader}>
      {!!Object.getOwnPropertyNames(project).length && (
        <>
          <p className={s.projectNameHeader__name}>{`${project.name || project.address.line1}`}</p>
          <div className={s.projectNameHeader__address}>
            <p>
              <span>{project.name !== project.address.line1 && `${project.address.line1}`},&nbsp;</span>
              <span>{`${project.address.formatCityAndState()}`}</span>
            </p>
            <a
              href={`${googleMapsBaseUrl + adressLink}`}
              target="_blank"
              className="no-print link fs18 fw600"
              rel="noreferrer"
            >
              View on map
            </a>
          </div>
        </>
      )}
    </div>
  );
};

export default ProjectNameHeader;
