/* eslint-disable no-param-reassign */
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {getOrganizations} from './asyncActions';
import {IOrgReducer, OrganizationsType, UserSettingsType} from './interfaces';

const initialState: IOrgReducer = {
  tenants: [],
};

const orgSlice = createSlice({
  name: 'org',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrganizations.fulfilled, (state, action: PayloadAction<OrganizationsType>) => {
      console.log('aaa:', action); // DEBUG
      state.tenants = action.payload;
    });
  },
});

export default orgSlice.reducer;
