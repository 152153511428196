import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {authenticateAva} from './asyncActions';

const initialState: any = {
  ava: null,
};

const avaSlice = createSlice({
  name: 'ava',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(authenticateAva.fulfilled, (state, action: PayloadAction<any>) => {
      // eslint-disable-next-line no-param-reassign
      state.ava = action.payload;
    });
  },
});

export default avaSlice.reducer;
