import {FC, RefObject, useMemo} from 'react';
import {FieldInputProps, FieldMetaProps} from 'formik';
import cx from 'classnames';

import s from './StepTextInput.module.scss';

interface IStepTextInputProps {
  autoCompleteRef?: RefObject<HTMLInputElement>;
  id: string;
  required?: boolean;
  width: string;
  onValueChange?: (value: string) => void;
  textAlign?: 'left' | 'center' | 'right';
  isValid?: boolean;
  field?: FieldInputProps<any>;
  meta?: FieldMetaProps<any>;
  defaultValue?: string;
  label?: string;
  placeholder?: string;
  maxLength?: number;
  fullWidth?: boolean;
  value?: string;
}

const StepTextInput: FC<IStepTextInputProps> = ({
  autoCompleteRef,
  id,
  width,
  required,
  defaultValue,
  isValid,
  onValueChange,
  maxLength,
  fullWidth,
  field,
  meta,
  label,
  placeholder,
  value,
  textAlign = 'left',
}) => {
  const responsiveInputWidth = useMemo(() => (fullWidth ? '100%' : width), [fullWidth, width]);

  return (
    <div className={cx(s.stepTextInput, {fullWidth})}>
      {label && (
        <label htmlFor={id} className={s.stepTextInput__label}>
          {label}
        </label>
      )}
      <input
        {...field}
        ref={autoCompleteRef}
        id={id}
        value={value}
        required={required}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={(e) => field?.onChange(e) || onValueChange?.(e.target.value)}
        className={cx(s.stepTextInput__item, {
          inputValid: isValid ?? (!meta?.error && (meta?.touched || field?.value)),
        })}
        style={{width: responsiveInputWidth, textAlign}}
        maxLength={maxLength}
      />
    </div>
  );
};
export default StepTextInput;
