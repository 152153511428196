import {FC, useState} from 'react';
import {Checkbox} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Link from '@mui/material/Link';
import {windowSizes} from 'core/_consts';
import cx from 'classnames';
import {AttachmentTypes} from 'core/enums/attachmentTypes';
import {extractDocumentFormat} from 'core/utils/regularExp';
import useWindowWidth from 'core/utils/hooks/useWindowWidth';
import s from './Document.module.scss';

const useStyles = makeStyles({
  link: {
    color: 'black',
    textDecoration: 'none',
  },
  checkBox: {
    padding: 0,
    marginRight: '5px',
    transform: 'scale(1.2)',
    '@media(max-width: 425px)': {
      display: 'none',
    },
  },
});

type DocumentProps = {
  name: string;
  selectedDocuments: any;
  setSelectedDocuments: (value: Array<string>) => void;
  creationDate: string;
  attachmentData: any;
  type: string;
  url: string;
};

const Document: FC<DocumentProps> = ({
  name,
  selectedDocuments,
  setSelectedDocuments,
  creationDate,
  type,
  url,
  attachmentData,
}) => {
  const [isSelected, setIsSelected] = useState(false);
  const windowWidth = useWindowWidth();

  const classes = useStyles();

  const updateDocumentStatus = () => {
    const newDocs = isSelected
      ? selectedDocuments.filter((document: any) => document.url !== url)
      : [...selectedDocuments, attachmentData];
    setSelectedDocuments(newDocs);
    setIsSelected((prev) => !prev);
  };

  const dynamicDocumentName = windowWidth <= windowSizes.mobileMiddle ? type : name;

  const documentFormat = name.match(extractDocumentFormat) || AttachmentTypes.other;

  return (
    <div className={s.document}>
      {/* <Checkbox
        style={{display: 'none'}}
        className={classes.checkBox}
        color="primary"
        disableRipple
        checked={isSelected}
        onClick={updateDocumentStatus}
      /> */}
      <Link href={url} className={cx(classes.link, s.document__content)} target="_blank">
        <img
          src={`/images/attachments/${
            AttachmentTypes[String(documentFormat).toLowerCase() as keyof typeof AttachmentTypes]
          }.svg`}
          alt="icon"
        />
        <p className={s.document__name}>{dynamicDocumentName}</p>
        <p className={s.document__date}>{creationDate}</p>
      </Link>
    </div>
  );
};

export default Document;
